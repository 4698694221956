<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col class="text-left">
          <h1>{{ actualManufacturingOrder.name }}<p>ID: {{ actualManufacturingOrder.id }}</p>
          </h1>
        </b-col>
      </b-row>
      <b-row class="text-left">
        <b-col>
          <b-button :disabled="lockButtons" v-show="actualManufacturingOrder.state == 'draft'"
            @click="sendPO('confirm')" size="sm mr-3" variant="success" class="ml-2 mb-2">
            Confirmar
          </b-button>
          <b-button :disabled="lockButtons" v-show="actualManufacturingOrder.state == 'confirmed'"
            @click="sendPO('start')" size="sm mr-3" variant="success" class="ml-2 mb-2">
            Iniciar Proceso
          </b-button>
          <b-button :disabled="lockButtons" v-show="actualManufacturingOrder.state == 'in_progress'"
            @click="sendPO('complete')" size="sm mr-3" variant="success" class="ml-2 mb-2">
            Terminar Proceso
          </b-button>
          <b-button :disabled="lockButtons" v-show="actualManufacturingOrder.state != 'cancel'"
            @click="sendPO('cancel')" size="sm mr-3" variant="danger" class="ml-2 mb-2">
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <progress-steps :steps="processSteps" :currentStep="actualManufacturingOrder.state"></progress-steps>
        </b-col>
      </b-row>
      <b-card class="my-2 p-1">
        <b-row>
          <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Sucursal:</b></td>
                  <td>{{ actualManufacturingOrder.sucursal_id }}</td>
                </tr>
                
                <tr>
                  <td><b>Status:</b></td>
                  <td><b-badge :variant="statusBadge(actualManufacturingOrder.state).variant">{{
                    statusBadge(actualManufacturingOrder.state).text }}</b-badge></td>
                </tr>
                <tr>
                  <td><b>Creado Por:</b></td>
                  <td>{{ actualManufacturingOrder.create_uid }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col lg="6" md="12">
            <table class="table  table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Fecha de Creación:</b></td>
                  <td>{{ actualManufacturingOrder.create_date }}</td>
                </tr>
                <tr>
                  <td><b>Fecha de entrega:</b></td>
                  <td>{{ actualManufacturingOrder.date_planned_start }}</td>
                </tr>
                <tr>
                  <td><b>Fecha de Orden:</b></td>
                  <td>{{ actualManufacturingOrder.date_planned_finished }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <h4>Por Consumir</h4>
            <table class="table table-sm text-left table-responsive">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Codigo</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in actualManufacturingOrderItemsRaw" v-bind:key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.supply.code }}</td>
                  <td>{{ item.supply.name }}</td>
                  <td>{{ item.quantity }}/{{ item.product_qty }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col>
            <h4>Por Elaborar</h4>
            <table class="table table-sm text-left table-responsive">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Codigo</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                 
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in actualManufacturingOrderItemsCons" v-bind:key="item.id">
                  <td>{{ item.id }}</td>
                  <td>{{ item.supply.code }}</td>
                  <td>{{ item.supply.name }}</td>
                  <td>{{ item.quantity }}/{{ item.product_qty }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
  </div>
</template>
<style>
.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
  border: none;

}
</style>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ProgressSteps from "../components/ProgressSteps.vue";
export default {
  name: "Compra",
  components: {
    Nav, ProgressSteps
  },

  data() {
    return {
      lockButtons: false,
      processSteps: [
        { name: "draft", label: "Borrador" },
        { name: "confirmed", label: "Confirmado" },
        { name: "in_progress", label: "En Proceso" },
        { name: "done", label: "Hecho" },
        { name: "cancelled", label: "Cancelado" },
      ],
      addingShoppingList: false,
      selectedShoppingList: null,
      shoppingListItems: [],
      shoppingListItemList: [],
      shoppingLists: [],
      supplies: [],
      actualManufacturingOrder: {
        bom_id: null,
        company_id: null,
        create_date: null,
        create_uid: null,
        date_finished: null,
        date_planned_finished: null,
        date_planned_start: null,
        date_start: null,
        id: null,
        location_dest_id: null,
        location_src_id: null,
        name: null,
        product_qty: null,
        qty_producing: null,
        state: null,
        sucursal_id: null,
        supply_id: null,
        write_date: null,
        write_uid: null
      },
      actualManufacturingOrderItems: [],
      actualManufacturingOrderItemsCons: [],
      actualManufacturingOrderItemsRaw: [],

      newItem: {
        id: null,
        purchase_order_id: null,
        supply_id: null,
        name: "",
        discount: 0,
        price_unit: 0,
        price_subtotal: 0,
        price_tax: 0,
        price_total: 0,
        product_quantity: 1,
        qty_invoiced: 0,
        qty_received: 0,
        qty_to_invoice: 0,
      }
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.actualManufacturingOrder.id = this.$route.params.id;
      this.getCompra()
      this.getCompraItems()
      // this.getSupplies()
      // this.emptyNewItem()
      // this.getShoppingLists()
    }
  },
  created() {

  },
  methods: {
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "draft":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "confirmed":
          rtn.variant = "warning"
          rtn.text = "Confirmado"
          break;
        case "in_progress":
          rtn.variant = "info"
          rtn.text = "En Proceso"
          break;
        case "done":
          rtn.variant = "success"
          rtn.text = "Terminado"
          break;
        case "cancelled":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    focusTaxes() {
      let that = this
      console.log("qtyDone")
      setTimeout(function () {
        that.$refs.supplyTax.$el.focus()
      }, 50)
    },
    focusSupplySelect() {
      if (this.newItem.supply_id == null) {
        this.$refs.supplySelect.$refs.search.focus()
      } else {
        this.$refs.supplyQty.$el.focus()
      }
    },
    selectedProduct() {
      let that = this
      setTimeout(function () {
        console.log("focusing")
        that.$refs.supplyQty.$el.focus()
      }, 100)
    },
    qtyDone() {
      let that = this
      console.log("qtyDone")
      setTimeout(function () {
        that.$refs.supplyPrice.$el.focus()
      }, 100)

    },
    sendPO(action) {
      this.$bvModal.msgBoxConfirm('Seguro que quieres cambiar de status', {
        title: 'Confirmacion',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Si',
        cancelTitle: "No",
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          if (value) {
            this.lockButtons = true
            let that = this
            axios.post('/manufacturingorder/' + this.actualManufacturingOrder.id + '/' + action)
              .then(function () {
                that.getCompra()
                that.getCompraItems()
                that.makeToast("success", "Enviado", "exito")
                that.lockButtons = false
              })
              .catch(function (error) {
                that.makeToast("danger", "Error", error.response.data);
                that.lockButtons = false
              });
          }

        })
        .catch(err => {
          console.log(err)
        })

    },
    downloadExcel() {
      let that = this
      axios.get('/purchaseorder/' + this.actualManufacturingOrder.id + '/excel', {
        "responseType": "blob",
        headers: {
          'Accept': '*/*',
        }
      })
        .then(function (response) {
          console.log(response.headers)
          console.log(response)
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = response.headers['content-disposition'];
          let filename = 'downloaded_file.xlsx'; // Fallback filename in case the header is missing
          if (contentDisposition && contentDisposition.includes('filename')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
            }
          }
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => that.makeToast("danger", "Error", error.response.data));

    },
    recalculateNewItem() {
      if (this.newItem.product_quantity < 0) {
        this.newItem.product_quantity = 1
      }
      if (this.newItem.price_unit < 0) {
        this.newItem.price_unit = 1
      }
      this.newItem.price_subtotal = this.newItem.product_quantity * this.newItem.price_unit
      this.newItem.price_total = this.newItem.price_subtotal
    },
    emptyNewItem() {
      this.newItem = {
        id: null,
        purchase_order_id: this.actualManufacturingOrder.id,
        supply_id: null,
        name: "",
        discount: 0,
        price_unit: null,
        price_subtotal: 0,
        price_tax: 0,
        price_total: 0,
        product_quantity: null,
        qty_invoiced: 0,
        qty_received: 0,
        qty_to_invoice: 0,
      }
    },
    getSupplies() {
      let that = this
      axios.get("/supplies")
        .then(function (res) {
          that.supplies = res.data.map(function (el) {
            el.name = el.code + " - " + el.name
            return el
          })
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getShoppingLists() {
      let that = this
      axios.get("/shoppinglists")
        .then(function (res) {
          that.shoppingLists = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    addShoppinglistitemsToPO() {
      if (this.addingShoppingList) {
        this.makeToast("danger", "Error", "La lista esta siendo cargada, espera un momento")
        return
      }
      this.addingShoppingList = true
      let that = this
      axios.post("/purchaseorder/" + this.actualManufacturingOrder.id + "/items/addShoppingList/" + this.selectedShoppingList)
        .then(function (res) {
          console.log(res)
          that.$bvModal.hide("modal-loadList")
          that.emptyNewItem()
          that.getCompraItems()
          that.addingShoppingList = false
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getShoppingListItems() {
      let that = this
      axios.get("/shoppinglist/" + this.selectedShoppingList + "/items")
        .then(function (res) {
          that.shoppingListItems = res.data
          let av = that.shoppingListItems.map(function (item) {
            return that.supplies.find((e) => e.id == item.supply_id)
          })
          that.shoppingListItemList = av
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getCompra() {
      let that = this
      axios.get("/manufacturingorder/" + this.actualManufacturingOrder.id)
        .then(function (res) {
          that.actualManufacturingOrder = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    recalculatePO() {
      let that = this
      axios.get("/purchaseorder/" + this.actualManufacturingOrder.id + "/recalculate")
        .then(function () {
          that.getCompra()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getCompraItems() {
      let that = this
      axios.get("/manufacturingorder/" + this.actualManufacturingOrder.id + "/items")
        .then(function (res) {
          that.actualManufacturingOrderItems = res.data
          that.actualManufacturingOrderItemsRaw = res.data.filter((x)=>x.raw_manufacturing_order_id>0)
          that.actualManufacturingOrderItemsCons = res.data.filter((x)=>x.manufacturing_order_id>0)
          
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    deleteCompraItems(id) {
      let that = this
      axios.delete("/purchaseorder/" + this.actualManufacturingOrder.id + "/item/" + id)
        .then(function () {
          that.getCompraItems()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getSucursal() {
      let that = this;
      axios
        .get("/restaurant/id")
        .then(function (response) {
          that.actualSucursal = response.data;
          that.getCompras()
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("danger", "Error", err.response.data);
        });
    },
    getCompraItem(id) {
      let that = this
      axios.get("/purchaseorder/" + this.actualManufacturingOrder.id + "/item/" + id, this.newItem)
        .then(function (res) {
          that.newItem = res.data
          that.$bvModal.show('modal-newProduct')
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    createNewItem() {
      let that = this
      this.newItem.purchase_order_id = parseInt(this.actualManufacturingOrder.id)
      let fo_it = this.supplies.find((e) => e.id == this.newItem.supply_id)
      this.newItem.name = fo_it.name
      if (this.newItem.id == null) {
        let elm = this.newItem
        delete elm.id
        axios.post("/purchaseorder/" + this.actualManufacturingOrder.id + "/item", elm)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getCompra()
              that.getCompraItems()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      } else {
        axios.put("/purchaseorder/" + this.actualManufacturingOrder.id + "/item/" + this.newItem.id, this.newItem)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getCompraItems()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      }

    },
    makeToast(variant = null, title = "", text = "") {
      if (variant == "success") {
        this.$bvToast.toast(text, {
          title: title,
          variant: variant,
          solid: true,
          appendToast: true,
        });
      } else {
        this.$bvModal.msgBoxOk(text, {
          title: title,
          okVariant: variant,
          centered: true
        })
      }
    },
  },
};
</script>
