<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1>Programas de Lealtad</h1>
        </b-col>
      </b-row>
      
      <b-tabs v-model="activeTab" content-class="mt-3">
        <b-tab title="Lista de Programas">
          <b-row class="mb-3">
            <b-col>
              <b-button-group>
                <b-button variant="primary" @click="createNewProgram" class="mr-2">
                  <i class="fas fa-plus mr-1"></i> Crear Programa
                </b-button>
                <b-button variant="outline-secondary" @click="toggleQuickView">
                  <i class="fas fa-table mr-1"></i> {{ showQuickView ? 'Ocultar Vista Rápida' : 'Ver Resumen' }}
                </b-button>
              </b-button-group>
            </b-col>
          </b-row>
          
          <!-- Vista Rápida -->
          <program-quick-view 
            v-if="showQuickView" 
            :programs="programs" 
            class="mb-4"
            @view-details="viewProgramDetails"
            @edit="editProgram"
          ></program-quick-view>
          
          <b-row v-if="loading">
            <b-col class="text-center py-4">
              <b-spinner label="Cargando..."></b-spinner>
              <p class="mt-2">Cargando programas de lealtad...</p>
            </b-col>
          </b-row>
          
          <template v-else-if="!showQuickView">
            <b-row v-if="programs.length === 0">
              <b-col class="text-center py-5">
                <p class="text-muted">No hay programas de lealtad creados</p>
                <b-button variant="outline-primary" @click="createNewProgram" class="mt-3">
                  Crear mi primer programa
                </b-button>
              </b-col>
            </b-row>
            
            <b-card v-for="program in programs" :key="program.id" class="mb-3">
              <b-row>
                <b-col md="8">
                  <h5>{{ program.name }}</h5>
                  <div class="d-flex align-items-center mb-2">
                    <span :class="['status-dot', program.active ? 'active' : 'inactive']"></span>
                    <small class="text-muted ml-2">{{ program.active ? 'Activo' : 'Inactivo' }}</small>
                  </div>
                  <p class="mb-1">
                    <b>Tipo:</b> {{ getProgramTypeLabel(program.program_type) }}
                  </p>
                  <p class="mb-1">
                    <b>Reglas:</b> {{ program.rules ? program.rules.length : 0 }} | 
                    <b>Recompensas:</b> {{ program.rewards ? program.rewards.length : 0 }}
                  </p>
                </b-col>
                <b-col md="4" class="d-flex justify-content-end align-items-center">
                  <b-button variant="outline-secondary" size="sm" class="mr-2"
                    @click="viewProgramDetails(program)">
                    Ver Detalles
                  </b-button>
                  <b-button variant="outline-primary" size="sm"
                    @click="editProgram(program)">
                    <i class="fas fa-edit"></i>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </template>
        </b-tab>
        
       <b-tab :title="editingProgram ? 'Editar Programa' : 'Nuevo Programa'">  <!-- :disabled="activeTab !== 1" -->
          <program-form 
            :program="editingProgram" 
            @cancel="activeTab = 0"
            @saved="onProgramSaved" />
        </b-tab>
        
        <b-tab title="Detalles del Programa" > <!-- :disabled="activeTab !== 2" -->
          <program-details 
            v-if="selectedProgram" 
            :program="selectedProgram" 
            @edit="editSelectedProgram" 
            @back="activeTab = 0" />
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
import Nav from "../../components/Nav.vue";
import ProgramForm from "./LoyaltyProgramForm.vue";
import ProgramDetails from "./LoyaltyProgramDetails.vue";
import ProgramQuickView from "./LoyaltyProgramQuickView.vue";
import axios from "axios";

export default {
  name: "LoyaltyProgram",
  components: { 
    Nav,
    ProgramForm,
    ProgramDetails,
    ProgramQuickView
  },
  data() {
    return {
      programs: [],
      loading: true,
      error: null,
      activeTab: 0,
      editingProgram: null,
      selectedProgram: null,
      showQuickView: false
    };
  },
  mounted() {
    this.fetchPrograms();
  },
  methods: {
    fetchPrograms() {
      this.loading = true;
      axios.get("/loyalty/programs")
        .then(response => {
          this.programs = response.data;
          this.loading = false;
        })
        .catch(error => {
          this.error = "Error al cargar los programas de lealtad: " + error.message;
          this.loading = false;
          this.makeToast("Error", this.error, "danger");
        });
    },
    
    createNewProgram() {
      this.editingProgram = null;
      this.activeTab = 1;
    },
    
    editProgram(program) {
      this.editingProgram = {...program};
      this.activeTab = 1;
    },
    
    viewProgramDetails(program) {
      this.selectedProgram = {...program};
      this.activeTab = 2;
    },
    
    editSelectedProgram() {
      this.editingProgram = {...this.selectedProgram};
      this.activeTab = 1;
    },
    
    onProgramSaved() {
      this.fetchPrograms();
      this.activeTab = 0;
      this.editingProgram = null;
      this.makeToast("Éxito", "Programa guardado correctamente", "success");
    },
    
    toggleQuickView() {
      this.showQuickView = !this.showQuickView;
    },
    
    getProgramTypeLabel(type) {
      const types = {
        'discount': 'Descuento',
        'buy_x_get_y': 'Compra X y Lleva Y',
        'free_product': 'Producto Gratis',
        'free_products': 'Productos Gratis',
        'coupons': 'Cupón de Descuento'
      };
      return types[type] || type;
    },
    
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    }
  }
};
</script>

<style scoped>
.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.status-dot.active {
  background-color: #fdfdfd;
}
.status-dot.inactive {
  background-color: #dc3545;
}
</style>
