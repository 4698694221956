<template>
  <div>
    <Nav></Nav>
    <b-container>
      <b-row>
        <b-col>
          <h1>Usuarios</h1>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button variant="default" @click="createNewUserInit">Crear usuario</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="search"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table responsive small stacked="sm" :filter="search" striped hover :items="usersTable"
            :fields="tableFields">
            <template #cell(editPassword)="data">
              <b-button size="sm" @click="cambiaPassword(data.item.id)" variant="success">Editar</b-button>
            </template>
            <template #cell(status)="data">
              <b-badge pill v-if="data.item.status" variant="success">Habilitado</b-badge>
              <b-badge pill v-else variant="danger">Deshabilitado</b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <b-modal centered ref="modal-createUser" title="Crear Usuario" size="xl" @close="cleanNewUser">

      <b-row>
        <b-col cols="3">
          <b-form-group label="ID">
            <b-form-input v-model="newuser.id" disabled required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="9">
          <b-form-group label="Nombre">
            <b-form-input v-model="newuser.name" :disabled="newuser.nameDisable" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Usuario:">
            <b-form-input v-model="newuser.username" :disabled="newuser.usernameDisable" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Password:" description="Si no deseas cambiar el password dejalo en blanco">
            <b-form-input v-model="newuser.password" :disabled="newuser.passwordDisable" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Correo:">
            <b-form-input v-model="newuser.email" :disabled="newuser.emailDisable" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Telefono (Con codigo de pais):">
            <b-form-input v-model="newuser.cellphone" :disabled="newuser.cellphoneDisable" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">

          <b-form-checkbox :value="1" :unchecked-value="0" v-model="newuser.status" :disabled="newuser.statusDisable">
            Habilitado
          </b-form-checkbox>

        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col>
          <b-button @click="cleanNewUser()" variant="danger">Cancelar</b-button>
          <b-button @click="newUser()" variant="success">Registrar</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="newuser.id != 0">
        <b-col>
          <b-card header="Grupos" header-tag="header">
            <div>
              <b-row>
                <b-col>
                  <p>Aquí podrás asignar al usuario a grupos que tienen permisos definidos, si quieres revisa <a
                      @click="$router.push('/userGroups')">Grupos</a></p>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="9">
                  <b-form-group label="Grupo a Agregar:" description="Selecciona un grupo que deseas agregar">
                    <b-form-select v-model="selectedGroup" value-field="id" text-field="name"
                      :options="groups"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-button size="sm" @click="addUserToGroup()" variant="success">Agregar</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table responsive small stacked="sm" striped hover :items="userGroups" :fields="grouptableFields">
                    <template #cell(buttons)="data">
                      <b-button size="sm" @click="deleteUserFromGroup(data.item.id)" variant="danger">Quitar</b-button>
                    </template>
                    <template #cell(status)="data">
                      <b-badge pill v-if="data.item.status" variant="success">Habilitado</b-badge>
                      <b-badge pill v-else variant="danger">Deshabilitado</b-badge>
                    </template>
                  </b-table>
                </b-col>
              </b-row>


            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="newuser.id != 0">
        <b-col>
          <b-card header="Sucursales Autorizadas" header-tag="header">
            <div>
              <b-row>
                <b-col>
                  <p>Aquí podrás asignar las sucursales a las que el usuario tendrá acceso</p>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col cols="9">
                  <b-form-group label="Sucursal a Agregar:"
                    description="Selecciona una sucursal para dar acceso al usuario">
                    <b-form-select v-model="selectedSucursal" value-field="id" text-field="nombre"
                      :options="sucursals"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="3" class="d-flex align-items-center">
                  <b-button size="sm" @click="addUserToSucursal()" variant="success">Agregar</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table responsive small stacked="sm" striped hover :items="userSucursals"
                    :fields="sucursaltableFields">
                    <template #cell(buttons)="data">
                      <b-button size="sm" @click="deleteUserFromSucursal(data.item.id)"
                        variant="danger">Quitar</b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="newuser.id != 0">
        <b-col>
          <b-card header="Permisos" header-tag="header">
            <div>
              <p>Aquí podrás asignar permisos exclusivos al usuario que estás editando, no es recomendable asignarlos,
                pero hazlo solo en caso que necesites que unicamente ese usuario tenga dichos permisos</p>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
export default {
  name: "Signup",
  components: {
    Nav
  },
  data() {
    return {
      selectedSucursal: 0,
      userSucursals: [],
      sucursals: [],
      sucursaltableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "nombre", label: "Nombre", sortable: true },
        { key: "code", label: "Código", sortable: true },
        { key: "buttons", label: "", sortable: false },
      ],

      newuser: {
        id: 0,
        name: "",
        nameDisable: false,
        password: "",
        passwordDisable: false,
        email: "test@mail.com",
        emailDisable: false,
        username: "",
        usernameDisable: false,
        cellphone: "5511223344",
        cellphoneDisable: false,
        editing: false,
        status: 1,
        statusDisable: false,
      },
      selectedGroup: 0,
      userGroups: [],
      usersTable: [],
      groups: [],
      search: "",
      tableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "username", label: "Nombre de usuario", sortable: true },
        { key: "email", label: "Mail", sortable: true },
        { key: "cellphone", label: "Telefono", sortable: true },
        { key: "status", label: "", sortable: true },
        { key: "editPassword", label: "", sortable: true },
      ],
      grouptableFields: [
        { key: "id", label: "ID", sortable: true, sortDirection: "desc" },
        { key: "name", label: "Nombre", sortable: true },
        { key: "buttons", label: "", sortable: false },
      ],
    };
  },
  mounted() {
    this.getAllUsers();
    this.getGroups()
    this.getAllSucursals(); // Add this line
  },
  methods: {
    createNewUserInit() {
      this.$refs['modal-createUser'].show()
      this.cleanNewUser()
    },
    cambiaPassword(id) {
      this.$refs['modal-createUser'].show()
      this.newuser.cellphoneDisable = false;
      this.newuser.nameDisable = true;
      this.newuser.passwordDisable = false;
      this.newuser.emailDisable = false;
      this.newuser.usernameDisable = true;
      this.newuser.id = id;
      this.fillActualUser();
    },
    getUserGroups() {
      let that = this;
      axios
        .get("/permission/user/" + this.newuser.id + "/groups")
        .then(function (response) {
          console.log(response.data);
          that.userGroups = response.data
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getGroups() {
      let that = this;
      axios
        .get("/permission/groups")
        .then(function (response) {
          console.log(response.data);
          that.groups = response.data
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    addUserToGroup() {
      let that = this;
      axios
        .post("/permission/user/" + this.newuser.id + "/group/" + this.selectedGroup)
        .then(function (response) {
          console.log(response.data);
          that.getUserGroups()
          that.getUserSucursals()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    deleteUserFromGroup(group_id) {
      let that = this;
      axios
        .delete("/permission/user/" + this.newuser.id + "/group/" + group_id)
        .then(function (response) {
          console.log(response.data);
          that.getUserGroups()
          that.getUserSucursals()
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    fillActualUser() {
      let that = this;
      console.log("/user/" + this.newuser.id);
      axios
        .get("/user/" + this.newuser.id)
        .then(function (response) {
          console.log(response.data);
          that.newuser.id = response.data.id;
          that.newuser.cellphone = response.data.cellphone;
          that.newuser.name = response.data.name;
          that.newuser.email = response.data.email;
          that.newuser.username = response.data.username;
          that.newuser.status = response.data.status;
          that.getUserGroups()
          that.getUserSucursals()

        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    cleanNewUser() {
      this.$refs['modal-createUser'].hide()

      this.newuser.id = 0;
      this.newuser.cellphone = "5511223344";
      this.newuser.name = "";
      this.newuser.password = "";
      this.newuser.email = "test@mail.com";
      this.newuser.username = "";

      this.newuser.cellphoneDisable = false;
      this.newuser.nameDisable = false;
      this.newuser.passwordDisable = false;
      this.newuser.emailDisable = false;
      this.newuser.usernameDisable = false;
    },
    newUser() {
      let that = this;
      if (this.newuser.id == 0) {
        axios
          .post("/user", this.newuser)
          .then(function (data) {
            console.log(data)
            that.cleanNewUser();
            that.getAllUsers();
            that.search = data.data.username;
          })
          .catch(function (err) {
            console.error(err);
            that.makeToast("Error", err.request.response, "danger");
          });
      } else {
        axios
          .put("/user", this.newuser)
          .then(function (data) {
            console.log(data);
            that.cleanNewUser();
            that.getAllUsers();
          })
          .catch(function (err) {
            console.error(err);
            that.makeToast("Error", err.request.response, "danger");
          });
      }
    },
    getAllUsers() {
      let that = this;
      axios
        .get("/users")
        .then(function (response) {
          that.usersTable = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getUserSucursals() {
      let that = this;
      axios
        .get(`/sucursal/user/${this.newuser.id}/assignments`)
        .then(function (response) {
          // Get the assignments
          const assignments = response.data;

          // Create a map of sucursal details from the sucursals array
          const sucursalMap = that.sucursals.reduce((acc, sucursal) => {
            acc[sucursal.id] = sucursal;
            return acc;
          }, {});

          // Enrich the assignments with sucursal details
          that.userSucursals = assignments.map(assignment => ({
            id: assignment.sucursal_id,
            ...sucursalMap[assignment.sucursal_id]
          })).filter(item => item.nombre); // Only include items that have matching sucursal details
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    getAllSucursals() {
      let that = this;
      axios
        .get("/sucursals")
        .then(function (response) {
          that.sucursals = response.data;
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },

    addUserToSucursal() {
      if (!this.selectedSucursal) {
        this.makeToast("Error", "Selecciona una sucursal", "warning");
        return;
      }

      let that = this;
      const data = {
        user_id: this.newuser.id,
        sucursal_id: this.selectedSucursal
      };

      axios
        .post("/sucursal/user/assignments", data)
        .then(function (response) {
          console.log(response)
          that.getUserSucursals();
          that.makeToast("Éxito", "Sucursal asignada correctamente", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },

    deleteUserFromSucursal(sucursal_id) {
      let that = this;
      axios
        .delete(`/sucursal/user/${this.newuser.id}/assignments/${sucursal_id}`)
        .then(function (response) {
          console.log(response)
          that.getUserSucursals();
          that.makeToast("Éxito", "Sucursal removida correctamente", "success");
        })
        .catch(function (err) {
          that.makeToast("Error", err.request.response, "danger");
        });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
  },
};
</script>
