<template>
  <div>
    <b-card no-body class="mb-4">
      <template #header>
        <h5 class="mb-0">
          Visualizador de Promoción
          <b-badge variant="info" class="ml-2">Vista Previa</b-badge>
        </h5>
      </template>
      
      <b-tabs pills card vertical>
        <!-- Vista para Cajero -->
        <b-tab title="Vista de Cajero" active>
          <b-card-body>
            <h6 class="border-bottom pb-2 mb-3">Información para el Cajero</h6>
            
            <div class="preview-card p-3 mb-4 border rounded">
              <div class="d-flex align-items-center justify-content-between mb-3">
                <h5 class="mb-0">{{ program.name }}</h5>
                <b-badge :variant="program.active ? 'success' : 'danger'">
                  {{ program.active ? 'Activo' : 'Inactivo' }}
                </b-badge>
              </div>
              
              <div class="mt-2">
                <strong>Aplicación:</strong>
                <div class="mt-1 mb-3">
                  <template v-if="hasCodes">
                    <div class="code-block p-2 mb-2 bg-light rounded">
                      <div><strong>Códigos válidos:</strong></div>
                      <div class="d-flex flex-wrap mt-1">
                        <b-badge 
                          v-for="rule in activeCodes" 
                          :key="rule.id" 
                          variant="primary"
                          class="mr-2 mb-1 p-2"
                        >
                          {{ rule.code }}
                        </b-badge>
                      </div>
                    </div>
                    <div class="small">El cliente debe presentar o mencionar uno de estos códigos.</div>
                  </template>
                  <template v-else>
                    <div class="alert alert-warning">
                      Esta promoción se aplica automáticamente al cumplir requisitos.
                    </div>
                  </template>
                </div>
                
                <div>
                  <strong>Requisitos:</strong>
                  <ul class="mb-0 mt-1">
                    <li v-for="(rule, index) in program.rules" :key="'req-'+index">
                      <template v-if="rule.reward_pointmode === 'order'">
                        Realizar una orden
                      </template>
                      <template v-else-if="rule.reward_pointmode === 'money'">
                        Gasto mínimo de ${{ rule.minimum_amount }}
                      </template>
                      <template v-else-if="rule.reward_pointmode === 'unit'">
                        <span v-if="rule.rule_products && rule.rule_products.length > 0">
                          Comprar al menos {{ rule.minimum_qty }} unidad(es) de productos específicos
                        </span>
                        <span v-else>
                          Comprar al menos {{ rule.minimum_qty }} unidad(es) 
                        </span>
                      </template>
                    </li>
                    <li v-if="program.limit_usage" class="text-danger">
                      No se puede combinar con otras promociones
                    </li>
                  </ul>
                </div>
                
                <hr>
                
                <div class="mt-3">
                  <strong>Beneficio para el cliente:</strong>
                  <div class="reward-block p-2 mt-1 bg-light rounded">
                    <div v-for="(reward, index) in program.rewards" :key="'rew-'+index" class="mb-2">
                      <div class="d-flex align-items-center">
                        <i class="fas fa-gift text-success mr-2"></i>
                        <strong>
                          <template v-if="reward.reward_type === 'discount'">
                            <template v-if="reward.discount_mode === 'percent'">
                              {{ reward.discount }}% de descuento
                              <template v-if="reward.discount_applicability === 'order'">
                                en toda la cuenta
                              </template>
                              <template v-else>
                                en productos específicos
                              </template>
                              <template v-if="reward.discount_max_amount > 0">
                                <span class="text-danger">
                                  (máximo ${{ reward.discount_max_amount }})
                                </span>
                              </template>
                            </template>
                            <template v-else-if="reward.discount_mode === 'per_order'">
                              ${{ reward.discount }} de descuento fijo
                            </template>
                            <template v-else>
                              ${{ reward.discount }} por punto acumulado
                            </template>
                          </template>
                          <template v-else-if="reward.reward_type === 'free_product'">
                            {{ reward.reward_product_qty }} 
                            {{ reward.reward_product_qty > 1 ? 'unidades' : 'unidad' }} 
                            gratis de producto #{{ reward.reward_product_id }}
                          </template>
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div v-if="program.require_extra" class="mt-3 alert alert-info">
                  <strong>Información adicional requerida:</strong>
                  <div>{{ program.extra_description }}</div>
                </div>
              </div>
            </div>
            
            <h6 class="border-bottom pb-2 mb-3">Instrucciones de Aplicación</h6>
            
            <ol>
              <li class="mb-2">
                <template v-if="hasCodes">
                  Cuando el cliente mencione el código <strong>{{ activeCodes[0]?.code }}</strong>, 
                  verifica que el pedido cumpla con los requisitos.
                </template>
                <template v-else>
                  Verifica que el pedido cumpla con los requisitos para aplicar la promoción automáticamente.
                </template>
              </li>
              <li class="mb-2">
                <template v-if="program.rewards && program.rewards.length > 0 && program.rewards[0].reward_type === 'discount'">
                  Al finalizar la orden, presiona el botón de "Aplicar Promoción" e ingresa el código.
                </template>
                <template v-else>
                  Al finalizar la orden, presiona el botón de "Canjear Producto" e ingresa el código.
                </template>
              </li>
              <li class="mb-2">
                Verifica que el descuento se haya aplicado correctamente en el ticket.
              </li>
              <li v-if="program.require_extra" class="mb-2">
                Solicita al cliente la información de: <strong>{{ program.extra_description }}</strong>
              </li>
              <li>
                Informa al cliente que se ha aplicado la promoción.
              </li>
            </ol>
          </b-card-body>
        </b-tab>
        
        <!-- Vista para Cliente -->
        <b-tab title="Vista de Cliente">
          <b-card-body>
            <h6 class="border-bottom pb-2 mb-3">Información para el Cliente</h6>
            
            <div class="customer-promo-card mb-4 p-4 border rounded bg-light">
              <div class="text-center mb-3">
                <h4 class="text-primary">{{ program.name }}</h4>
                <div class="badge badge-secondary">{{ getProgramTypeLabel(program.program_type) }}</div>
              </div>
              
              <div class="d-flex justify-content-center mb-3">
                <div class="promo-highlight p-3 bg-white rounded text-center" style="width: 80%;">
                  <template v-if="program.rewards && program.rewards.length > 0">
                    <div v-for="(reward, index) in program.rewards" :key="'cust-'+index">
                      <template v-if="reward.reward_type === 'discount' && reward.discount_mode === 'percent'">
                        <h2 class="text-danger mb-0">{{ reward.discount }}%</h2>
                        <div>DE DESCUENTO</div>
                      </template>
                      <template v-else-if="reward.reward_type === 'discount' && reward.discount_mode !== 'percent'">
                        <h2 class="text-danger mb-0">${{ reward.discount }}</h2>
                        <div>DE DESCUENTO</div>
                      </template>
                      <template v-else>
                        <h2 class="text-danger mb-0">¡GRATIS!</h2>
                        <div>{{ reward.reward_product_qty }} PRODUCTO(S)</div>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
              
              <div class="text-center mb-3">
                <template v-if="hasCodes">
                  <div class="mb-2">Usa el código:</div>
                  <div 
                    v-for="rule in activeCodes.slice(0, 1)" 
                    :key="'cust-code-'+rule.id" 
                    class="promo-code p-2 bg-dark text-white rounded d-inline-block"
                  >
                    <h5 class="mb-0">{{ rule.code }}</h5>
                  </div>
                </template>
                <template v-else>
                  <div class="alert alert-success d-inline-block">
                    ¡Se aplica automáticamente!
                  </div>
                </template>
              </div>
              
              <div class="mt-4">
                <div class="text-center mb-2"><strong>Condiciones:</strong></div>
                <ul class="mb-0 small text-center list-unstyled">
                  <li v-for="(rule, index) in program.rules" :key="'cust-req-'+index" class="mb-1">
                    <template v-if="rule.reward_pointmode === 'money' && rule.minimum_amount > 0">
                      • Compra mínima de ${{ rule.minimum_amount }}
                    </template>
                    <template v-else-if="rule.reward_pointmode === 'unit' && rule.minimum_qty">
                      • Compra al menos {{ rule.minimum_qty }} unidades de productos participantes
                    </template>
                  </li>
                  <li v-if="program.limit_usage" class="mb-1">
                    • No acumulable con otras promociones
                  </li>
                </ul>
              </div>
            </div>
            
            <h6 class="border-bottom pb-2 mb-3">Instrucciones para el Cliente</h6>
            
            <ol>
              <li class="mb-2">
                <template v-if="hasCodes">
                  Menciona el código <strong>{{ activeCodes[0]?.code }}</strong> al realizar tu pedido.
                </template>
                <template v-else>
                  La promoción se aplicará automáticamente al cumplir con los requisitos.
                </template>
              </li>
              <li v-if="program.require_extra" class="mb-2">
                Proporciona la siguiente información cuando se te solicite: <strong>{{ program.extra_description }}</strong>
              </li>
              <li>
                <template v-if="program.limit_usage">
                  Recuerda que esta promoción no es acumulable con otras.
                </template>
                <template v-else>
                  ¡Disfruta de tu promoción!
                </template>
              </li>
            </ol>
          </b-card-body>
        </b-tab>
        
        <!-- Diagrama de Flujo -->
        <b-tab title="Diagrama de Flujo">
          <b-card-body>
            <h6 class="border-bottom pb-2 mb-3">Proceso de Aplicación</h6>
            
            <div class="flow-diagram py-3">
              <!-- Inicio del flujo -->
              <div class="flow-step d-flex mb-4">
                <div class="flow-icon bg-primary text-white rounded-circle d-flex align-items-center justify-content-center">
                  <i class="fas fa-play"></i>
                </div>
                <div class="flow-content ml-3">
                  <h6>Inicio</h6>
                  <p>Cliente realiza pedido</p>
                </div>
              </div>
              
              <!-- Paso 1: Verificación de requisitos -->
              <div class="flow-connector"></div>
              <div class="flow-step d-flex mb-4">
                <div class="flow-icon bg-info text-white rounded-circle d-flex align-items-center justify-content-center">
                  <i class="fas fa-check-circle"></i>
                </div>
                <div class="flow-content ml-3">
                  <h6>Verificación de Requisitos</h6>
                  <ul class="mb-0 small">
                    <template v-for="(rule, index) in program.rules">
                      <li :key="'flow-req-'+index">
                        <template v-if="rule.reward_pointmode === 'order'">
                          Pedido registrado
                        </template>
                        <template v-else-if="rule.reward_pointmode === 'money'">
                          Total >= ${{ rule.minimum_amount || 0 }}
                        </template>
                        <template v-else-if="rule.reward_pointmode === 'unit'">
                          Cantidad de productos >= {{ rule.minimum_qty || 1 }}
                        </template>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
              
              <!-- Paso 2: Código de promoción (si aplica) -->
              <template v-if="hasCodes">
                <div class="flow-connector"></div>
                <div class="flow-step d-flex mb-4">
                  <div class="flow-icon bg-warning text-white rounded-circle d-flex align-items-center justify-content-center">
                    <i class="fas fa-key"></i>
                  </div>
                  <div class="flow-content ml-3">
                    <h6>Código de Promoción</h6>
                    <p>Cliente presenta código: <strong>{{ activeCodes[0]?.code }}</strong></p>
                  </div>
                </div>
              </template>
              
              <!-- Paso 3: Información adicional (si aplica) -->
              <template v-if="program.require_extra">
                <div class="flow-connector"></div>
                <div class="flow-step d-flex mb-4">
                  <div class="flow-icon bg-secondary text-white rounded-circle d-flex align-items-center justify-content-center">
                    <i class="fas fa-info-circle"></i>
                  </div>
                  <div class="flow-content ml-3">
                    <h6>Información Adicional</h6>
                    <p>Se solicita: <strong>{{ program.extra_description }}</strong></p>
                  </div>
                </div>
              </template>
              
              <!-- Paso 4: Aplicación de beneficio -->
              <div class="flow-connector"></div>
              <div class="flow-step d-flex mb-4">
                <div class="flow-icon bg-success text-white rounded-circle d-flex align-items-center justify-content-center">
                  <i class="fas fa-gift"></i>
                </div>
                <div class="flow-content ml-3">
                  <h6>Aplicación de Beneficio</h6>
                  <template v-if="program.rewards && program.rewards.length > 0">
                    <div v-for="(reward, index) in program.rewards" :key="'flow-rew-'+index">
                      <template v-if="reward.reward_type === 'discount'">
                        <p>
                          Se aplica descuento de 
                          <strong>
                            {{ reward.discount_mode === 'percent' ? `${reward.discount}%` : `$${reward.discount}` }}
                          </strong>
                          <template v-if="reward.discount_applicability === 'order'">
                            a toda la cuenta
                          </template>
                          <template v-else>
                            a productos específicos
                          </template>
                        </p>
                      </template>
                      <template v-else>
                        <p>
                          Cliente recibe {{ reward.reward_product_qty }} 
                          {{ reward.reward_product_qty > 1 ? 'unidades' : 'unidad' }} 
                          del producto #{{ reward.reward_product_id }}
                        </p>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
              
              <!-- Paso 5: Finalización -->
              <div class="flow-connector"></div>
              <div class="flow-step d-flex">
                <div class="flow-icon bg-danger text-white rounded-circle d-flex align-items-center justify-content-center">
                  <i class="fas fa-flag-checkered"></i>
                </div>
                <div class="flow-content ml-3">
                  <h6>Finalización</h6>
                  <p>Se completa la transacción</p>
                </div>
              </div>
            </div>
            
            <div class="bg-light p-3 rounded mt-4">
              <h6>Notas Adicionales:</h6>
              <ul class="mb-0">
                <li v-if="program.limit_usage">
                  Si el cliente intenta aplicar otra promoción, se debe informar que no es posible combinarlas.
                </li>
                <li v-if="program.rewards && program.rewards.some(r => r.discount_max_amount > 0)">
                  El descuento tiene un límite máximo de ${{ program.rewards.find(r => r.discount_max_amount > 0).discount_max_amount }}.
                </li>
                <li>
                  La promoción estará activa mientras el sistema la muestre como "Activa".
                </li>
              </ul>
            </div>
          </b-card-body>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ProgramPreview",
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasCodes() {
      return this.program.rules && 
             this.program.rules.some(rule => rule.mode === 'with_code' && rule.active);
    },
    activeCodes() {
      if (!this.program.rules) return [];
      return this.program.rules.filter(rule => rule.mode === 'with_code' && rule.active);
    }
  },
  methods: {
    getProgramTypeLabel(type) {
      const types = {
        'discount': 'Descuento',
        'buy_x_get_y': 'Compra X y Lleva Y',
        'free_product': 'Producto Gratis',
        'free_products': 'Productos Gratis',
        'coupons': 'Cupón de Descuento'
      };
      return types[type] || type;
    }
  }
};
</script>

<style scoped>
.flow-diagram {
  position: relative;
}

.flow-step {
  position: relative;
  z-index: 2;
}

.flow-icon {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.flow-connector {
  position: absolute;
  left: 20px;
  width: 2px;
  height: 30px;
  background-color: #ccc;
  margin-left: -1px;
  z-index: 1;
}

.promo-code {
  letter-spacing: 1px;
}

.preview-card, .customer-promo-card {
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}
</style>
