<template>
  <b-card :title="isEdit ? 'Edit Table' : 'Add New Table'">
    <b-form @submit.prevent="saveTable">
      <b-row>
        <b-col md="4">
          <b-form-group label="Table Number:" label-for="table-number">
            <b-form-input
              id="table-number"
              v-model="tableData.table_number"
              required
              placeholder="e.g. A1, 12, etc."
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Capacity:" label-for="capacity">
            <b-form-input
              id="capacity"
              v-model.number="tableData.capacity"
              type="number"
              min="1"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Status:" label-for="status">
            <b-form-select
              id="status"
              v-model="tableData.status"
              :options="statusOptions"
              required
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <b-form-group label="X Position:" label-for="position-x">
            <b-form-input
              id="position-x"
              v-model.number="tableData.position_x"
              type="number"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Y Position:" label-for="position-y">
            <b-form-input
              id="position-y"
              v-model.number="tableData.position_y"
              type="number"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Active:" label-for="active">
            <b-form-checkbox
              id="active"
              v-model="tableData.active"
              switch
            >
              {{ tableData.active ? 'Active' : 'Inactive' }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group v-if="tableData.hash" label="Table QR Code Hash:" label-for="table-hash">
        <b-input-group>
          <b-form-input
            id="table-hash"
            v-model="tableData.hash"
            readonly
            class="bg-light"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="copyToClipboard(tableData.hash)" variant="outline-secondary">
              <i class="fas fa-copy"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <small class="text-muted">This hash is used for QR code generation</small>
      </b-form-group>

      <div class="d-flex justify-content-between mt-3">
        <b-button type="button" variant="secondary" @click="$emit('cancel')">
          Cancel
        </b-button>
        <b-button type="submit" variant="primary">
          {{ isEdit ? 'Update' : 'Create' }} Table
        </b-button>
      </div>
    </b-form>
  </b-card>
</template>

<script>
export default {
  name: 'TableEditor',
  props: {
    table: {
      type: Object,
      default: () => ({
        sucursal_id: null,
        floor_id: null,
        table_number: '',
        capacity: 4,
        status: 'available',
        position_x: 0,
        position_y: 0,
        active: true,
        hash: ''
      })
    },
    sucursalId: {
      type: [Number, String],
      required: true
    },
    floorId: {
      type: [Number, String],
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: { ...this.table },
      statusOptions: [
        { value: 'available', text: 'Available' },
        { value: 'occupied', text: 'Occupied' },
        { value: 'reserved', text: 'Reserved' },
        { value: 'maintenance', text: 'Maintenance' }
      ]
    }
  },
  watch: {
    table(newVal) {
      this.tableData = { ...newVal };
    },
    sucursalId(newVal) {
      this.tableData.sucursal_id = newVal;
    },
    floorId(newVal) {
      this.tableData.floor_id = newVal;
    }
  },
  created() {
    this.tableData.sucursal_id = Number(this.sucursalId);
    this.tableData.floor_id = Number(this.floorId);
  },
  methods: {
    saveTable() {
      // Ensure numbers are properly typed
      this.tableData.capacity = Number(this.tableData.capacity);
      this.tableData.position_x = Number(this.tableData.position_x);
      this.tableData.position_y = Number(this.tableData.position_y);
      this.tableData.active = this.tableData.active?1:0;
      
      this.$emit('save', this.tableData);
    },
    
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$bvToast.toast('Hash copied to clipboard', {
            title: 'Copied!',
            variant: 'success',
            solid: true,
            autoHideDelay: 1500
          });
        },
        () => {
          this.$bvToast.toast('Failed to copy to clipboard', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        }
      );
    }
  }
}
</script>