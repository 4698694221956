<template>
  <div>
    <b-card no-body>
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Vista Rápida de Promociones</h5>
          <b-button size="sm" variant="outline-secondary" @click="printTable">
            <i class="fas fa-print mr-1"></i> Imprimir
          </b-button>
        </div>
      </template>
      
      <b-card-body>
        <b-form-group label="Filtro:">
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Buscar promociones..."></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Limpiar</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        
        <b-form-checkbox v-model="showOnlyActive" switch class="mb-3">
          Mostrar solo promociones activas
        </b-form-checkbox>
        
        <div class="table-responsive">
          <b-table 
            id="promotions-table"
            :items="filteredPrograms" 
            :fields="fields"
            sort-icon-left
            striped
            hover
            responsive
            small
            :filter="filter"
            :filter-included-fields="['name', 'codes', 'type', 'discount']"
          >
            <!-- Columna de estado -->
            <template #cell(active)="data">
              <b-badge :variant="data.value ? 'success' : 'danger'">
                {{ data.value ? 'Activo' : 'Inactivo' }}
              </b-badge>
            </template>
            
            <!-- Columna de códigos -->
            <template #cell(codes)="data">
              <div v-if="data.value && data.value.length > 0">
                <b-badge 
                  v-for="(code, index) in data.value" 
                  :key="index"
                  variant="primary" 
                  class="mr-1 mb-1"
                >
                  {{ code }}
                </b-badge>
              </div>
              <div v-else class="text-muted">
                <small>Automático</small>
              </div>
            </template>
            
            <!-- Columna de tipo -->
            <template #cell(type)="data">
              {{ getProgramTypeLabel(data.value) }}
            </template>
            
            <!-- Columna de descuento/recompensa -->
            <template #cell(discount)="data">
              <span v-if="data.value">
                <span v-if="data.value.type === 'percent'">
                  <strong class="text-danger">{{ data.value.amount }}%</strong> 
                  <span class="text-nowrap">de descuento</span>
                </span>
                <span v-else-if="data.value.type === 'fixed'">
                  <strong class="text-danger">${{ data.value.amount }}</strong> 
                  <span class="text-nowrap">de descuento</span>
                </span>
                <span v-else-if="data.value.type === 'product'">
                  <strong class="text-danger">{{ data.value.amount }}</strong> 
                  <span class="text-nowrap">producto(s) gratis</span>
                </span>
              </span>
              <span v-else>-</span>
            </template>
            
            <!-- Columna de requisitos -->
            <template #cell(requirements)="data">
              <div v-if="data.value && data.value.minAmount > 0">
                Min: ${{ data.value.minAmount }}
              </div>
              <div v-else-if="data.value && data.value.minQty > 0">
                Min: {{ data.value.minQty }} unidad(es)
              </div>
              <div v-else>
                <small class="text-muted">Sin mínimo</small>
              </div>
            </template>
            
            <!-- Columna de combinación -->
            <template #cell(combinable)="data">
              <i :class="['fas', data.value ? 'fa-check text-success' : 'fa-times text-danger']"></i>
              {{ data.value ? 'Sí' : 'No' }}
            </template>
            
            <!-- Columna de acciones -->
            <template #cell(actions)="data">
              <b-button-group size="sm">
                <b-button variant="outline-secondary" @click="viewDetails(data.item)">
                  <i class="fas fa-eye"></i>
                </b-button>
                <b-button variant="outline-primary" @click="editProgram(data.item)">
                  <i class="fas fa-edit"></i>
                </b-button>
              </b-button-group>
            </template>
          </b-table>
        </div>
        
        <!-- Tabla para imprimir -->
        <div id="print-table" class="d-none">
          <h3 class="mb-4 text-center">Resumen de Promociones</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Promoción</th>
                <th>Código</th>
                <th>Descuento</th>
                <th>Requisitos</th>
                <th>Combinable</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in filteredPrograms" :key="index">
                <td>{{ item.name }}</td>
                <td>
                  <span v-if="item.codes && item.codes.length">
                    {{ item.codes.join(', ') }}
                  </span>
                  <span v-else>Automático</span>
                </td>
                <td>
                  <span v-if="item.discount">
                    <span v-if="item.discount.type === 'percent'">
                      {{ item.discount.amount }}% de descuento
                    </span>
                    <span v-else-if="item.discount.type === 'fixed'">
                      ${{ item.discount.amount }} de descuento
                    </span>
                    <span v-else-if="item.discount.type === 'product'">
                      {{ item.discount.amount }} producto(s) gratis
                    </span>
                  </span>
                </td>
                <td>
                  <span v-if="item.requirements && item.requirements.minAmount > 0">
                    Min: ${{ item.requirements.minAmount }}
                  </span>
                  <span v-else-if="item.requirements && item.requirements.minQty > 0">
                    Min: {{ item.requirements.minQty }} unidad(es)
                  </span>
                  <span v-else>Sin mínimo</span>
                </td>
                <td>{{ item.combinable ? 'Sí' : 'No' }}</td>
              </tr>
            </tbody>
          </table>
          <p class="mt-3 text-center">
            Fecha de impresión: {{ new Date().toLocaleDateString() }} {{ new Date().toLocaleTimeString() }}
          </p>
        </div>
        
        <div v-if="filteredPrograms.length === 0" class="text-center py-4 text-muted">
          No hay promociones que coincidan con los criterios de búsqueda.
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ProgramQuickView",
  props: {
    programs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filter: "",
      showOnlyActive: true,
      fields: [
        { key: 'active', label: 'Estado', sortable: true },
        { key: 'name', label: 'Promoción', sortable: true },
        { key: 'codes', label: 'Códigos', sortable: false },
        { key: 'type', label: 'Tipo', sortable: true },
        { key: 'discount', label: 'Descuento/Recompensa', sortable: false },
        { key: 'requirements', label: 'Requisitos', sortable: false },
        { key: 'combinable', label: 'Combinable', sortable: true },
        { key: 'actions', label: 'Acciones' }
      ]
    };
  },
  computed: {
    filteredPrograms() {
      let result = this.programs.map(program => {
        // Extraer códigos
        const codes = program.rules
          ? program.rules
              .filter(rule => rule.mode === 'with_code' && rule.active)
              .map(rule => rule.code)
          : [];
        
        // Extraer información de descuento/recompensa
        let discount = null;
        if (program.rewards && program.rewards.length > 0) {
          const reward = program.rewards[0];
          if (reward.reward_type === 'discount') {
            if (reward.discount_mode === 'percent') {
              discount = { type: 'percent', amount: reward.discount };
            } else {
              discount = { type: 'fixed', amount: reward.discount };
            }
          } else if (reward.reward_type === 'free_product') {
            discount = { type: 'product', amount: reward.reward_product_qty || 1 };
          }
        }
        
        // Extraer requisitos
        let requirements = { minAmount: 0, minQty: 0 };
        if (program.rules && program.rules.length > 0) {
          const rule = program.rules[0];
          if (rule.reward_pointmode === 'money' && rule.minimum_amount) {
            requirements.minAmount = rule.minimum_amount;
          } else if (rule.reward_pointmode === 'unit' && rule.minimum_qty) {
            requirements.minQty = rule.minimum_qty;
          }
        }
        
        return {
          id: program.id,
          active: program.active,
          name: program.name,
          codes,
          type: program.program_type,
          discount,
          requirements,
          combinable: !program.limit_usage,
          originalProgram: program
        };
      });
      
      // Filtrar por estado activo si es necesario
      if (this.showOnlyActive) {
        result = result.filter(p => p.active);
      }
      
      return result;
    }
  },
  methods: {
    getProgramTypeLabel(type) {
      const types = {
        'discount': 'Descuento',
        'buy_x_get_y': 'Compra X y Lleva Y',
        'free_product': 'Producto Gratis',
        'free_products': 'Productos Gratis',
        'coupons': 'Cupón de Descuento'
      };
      return types[type] || type;
    },
    
    viewDetails(item) {
      this.$emit('view-details', item.originalProgram);
    },
    
    editProgram(item) {
      this.$emit('edit', item.originalProgram);
    },
    
    printTable() {
      const printContents = document.getElementById('print-table').innerHTML;
      const originalContents = document.body.innerHTML;
      
      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;
      
      // Reiniciar event handlers después de restaurar el contenido
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
};
</script>

<style scoped>
@media print {
  #print-table {
    display: block !important;
  }
  
  body * {
    visibility: hidden;
  }
  
  #print-table, #print-table * {
    visibility: visible;
  }
  
  #print-table {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px;
  }
}
</style>