// src/services/tableService.js
import axios from 'axios';

export default {
  // Floor operations
  getAllFloors() {
    return axios.get('/sucursal/floors');
  },
  
  getFloorsBySucursal(sucursalId) {
    return axios.get(`/sucursal/${sucursalId}/floors`);
  },
  
  getFloorById(floorId) {
    return axios.get(`/sucursal/floor/${floorId}`);
  },
  
  createFloor(floorData) {
    return axios.post('/sucursal/floor', floorData);
  },
  
  updateFloor(floorId, floorData) {
    return axios.put(`/sucursal/floor/${floorId}`, floorData);
  },
  
  deleteFloor(floorId) {
    return axios.delete(`/sucursal/floor/${floorId}`);
  },
  
  // Table operations
  getTablesBySucursal(sucursalId) {
    return axios.get(`/sucursal/${sucursalId}/tables`);
  },
  
  getTablesByFloor(floorId) {
    return axios.get(`/sucursal/floor/${floorId}/tables`);
  },
  
  getTableById(tableId) {
    return axios.get(`/sucursal/table/${tableId}`);
  },
  
  getTableByHash(tableHash) {
    return axios.get(`/sucursal/table/hash/${tableHash}`);
  },
  
  createTable(tableData) {
    return axios.post('/sucursal/table', tableData);
  },
  
  updateTable(tableId, tableData) {
    return axios.put(`/sucursal/table/${tableId}`, tableData);
  },
  
  updateTableStatus(tableId, status) {
    return axios.patch(`/sucursal/table/${tableId}/status`, { status });
  },
  
  deleteTable(tableId) {
    return axios.delete(`/sucursal/table/${tableId}`);
  }
}
