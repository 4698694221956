<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1>Floor Management</h1>
          <p class="text-muted">Manage the floors in your restaurant</p>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">Floors</h3>
                <b-button variant="primary" size="sm" @click="showAddFloor">
                  <i class="fas fa-plus"></i> Add Floor
                </b-button>
              </div>
            </template>

            <!-- Sucursal selector -->
            <b-form-group label="Select Sucursal:">
              <b-form-select 
                v-model="selectedSucursalId"
                :options="sucursalOptions"
                @change="loadFloors">
              </b-form-select>
            </b-form-group>

            <!-- Floors table -->
            <b-table
              :items="floors"
              :fields="floorFields"
              responsive="sm"
              hover
              striped
              :busy="isLoading"
            >
              <template #cell(status)="data">
                <b-badge :variant="data.item.status ? 'success' : 'danger'">
                  {{ data.item.status ? 'Active' : 'Inactive' }}
                </b-badge>
              </template>

              <template #cell(actions)="data">
                <b-button-group size="sm">
                  <b-button variant="outline-primary" @click="editFloor(data.item)">
                    <i class="fas fa-edit"></i>
                  </b-button>
                  <b-button variant="outline-danger" @click="confirmDeleteFloor(data.item)">
                    <i class="fas fa-trash-alt"></i>
                  </b-button>
                  <b-button 
                    variant="outline-success" 
                    @click="goToTableManagement(data.item.id)"
                    v-b-tooltip.hover 
                    title="Manage tables on this floor">
                    <i class="fas fa-table"></i>
                  </b-button>
                </b-button-group>
              </template>

              <template #table-busy>
                <div class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>

          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Form Modal for Add/Edit Floor -->
    <b-modal
      id="floor-form-modal"
      ref="floorFormModal"
      :title="isEditing ? 'Edit Floor' : 'Add Floor'"
      @hide="cancelForm"
      @ok="saveFloor"
      ok-title="Save"
      ok-variant="primary"
      cancel-title="Cancel"
    >
      <b-form @submit.prevent="saveFloor">
        <b-form-group label="Floor Name:" label-for="floor-name">
          <b-form-input
            id="floor-name"
            v-model="floorForm.name"
            required
            placeholder="e.g. Main Floor, Terrace, etc."
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Description:" label-for="floor-description">
          <b-textarea
            id="floor-description"
            v-model="floorForm.description"
            rows="3"
            placeholder="Describe this floor"
          ></b-textarea>
        </b-form-group>

        <b-form-group label="Capacity:" label-for="floor-capacity">
          <b-form-input
            id="floor-capacity"
            v-model.number="floorForm.capacity"
            type="number"
            min="1"
            required
            placeholder="Maximum number of people"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Status:" label-for="floor-status">
          <b-form-checkbox
            id="floor-status"
            v-model="floorForm.status"
          >
            {{ floorForm.status ? 'Active' : 'Inactive' }}
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- Confirmation Modal for Delete -->
    <b-modal
      id="delete-floor-modal"
      ref="deleteModal"
      title="Confirm Delete"
      @ok="deleteFloor"
      ok-variant="danger"
      ok-title="Delete"
      cancel-title="Cancel"
    >
      <p>Are you sure you want to delete the floor "{{ floorToDelete ? floorToDelete.name : '' }}"?</p>
      <p class="text-danger">This will also delete all tables associated with this floor!</p>
    </b-modal>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';
import tableService from '@/services/tableService';
import axios from 'axios';
export default {
  name: 'FloorManagement',
  components: {
    Nav
  },
  data() {
    return {
      isLoading: false,
      floors: [],
      selectedSucursalId: null,
      sucursalOptions: [],
      floorFields: [
        { key: 'id', label: 'ID' },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'capacity', label: 'Capacity', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' }
      ],
      showFloorForm: false,
      isEditing: false,
      floorForm: {
        id: null,
        name: '',
        description: '',
        capacity: 50,
        sucursal_id: null,
        status: true
      },
      floorToDelete: null
    };
  },
  async created() {
    await this.loadSucursals();
    
    // Set default sucursal if available
    if (this.sucursalOptions.length > 0) {
      this.selectedSucursalId = this.sucursalOptions[0].value;
      this.loadFloors();
    }
  },
  methods: {
    async loadSucursals() {
      try {
        // This endpoint should be adjusted to match your actual API
        const response = await axios.get('/sucursals');
        this.sucursalOptions = response.data.map(sucursal => ({
          value: sucursal.id,
          text: sucursal.nombre || `Sucursal #${sucursal.id}`
        }));
      } catch (error) {
        this.showErrorToast('Failed to load sucursals', error);
      }
    },
    
    async loadFloors() {
      if (!this.selectedSucursalId) return;
      
      this.isLoading = true;
      try {
        const response = await tableService.getFloorsBySucursal(this.selectedSucursalId);
        this.floors = response.data;
      } catch (error) {
        this.showErrorToast('Failed to load floors', error);
      } finally {
        this.isLoading = false;
      }
    },
    
    showAddFloor() {
      this.isEditing = false;
      this.floorForm = {
        id: null,
        name: '',
        description: '',
        capacity: 50,
        sucursal_id: this.selectedSucursalId,
        status: true
      };
      this.$refs.floorFormModal.show();
    },
    
    editFloor(floor) {
      this.isEditing = true;
      this.floorForm = { ...floor };
      this.$refs.floorFormModal.show();
    },
    
    async saveFloor() {
      this.isLoading = true;
      this.floorForm.status = this.floorForm.status ? 1 : 0;
      console.log(this.floorForm);
      try {
        if (this.isEditing) {
          await tableService.updateFloor(this.floorForm.id, this.floorForm);
          this.showSuccessToast('Floor updated successfully');
        } else {
          await tableService.createFloor(this.floorForm);
          this.showSuccessToast('Floor created successfully');
        }
        
        // Refresh the floor list
        await this.loadFloors();
        
        // Hide the form
        this.$refs.floorFormModal.hide();
      } catch (error) {
        console.log(error);
        this.showErrorToast(
          this.isEditing ? 'Failed to update floor' : 'Failed to create floor',
          error.response?.data || error
        );
      } finally {
        this.isLoading = false;
      }
    },
    
    cancelForm() {
      this.$refs.floorFormModal.hide();
    },
    
    confirmDeleteFloor(floor) {
      this.floorToDelete = floor;
      this.$refs.deleteModal.show();
    },
    
    async deleteFloor() {
      if (!this.floorToDelete) return;
      
      this.isLoading = true;
      try {
        await tableService.deleteFloor(this.floorToDelete.id);
        this.showSuccessToast(`Floor "${this.floorToDelete.name}" deleted successfully`);
        
        // Refresh the floor list
        await this.loadFloors();
      } catch (error) {
        this.showErrorToast('Failed to delete floor', error);
      } finally {
        this.isLoading = false;
        this.floorToDelete = null;
      }
    },
    
    goToTableManagement(floorId) {
      this.$router.push({
        name: 'TableManagement',
        params: { 
          sucursalId: this.selectedSucursalId,
          floorId
        }
      });
    },
    
    showSuccessToast(message) {
      this.$bvToast.toast(message, {
        title: 'Success',
        variant: 'success',
        solid: true
      });
    },
    
    showErrorToast(message, error) {
      console.error(message, error);
      this.$bvToast.toast(error.response?.data?.message || error.message || 'An error occurred', {
        title: message,
        variant: 'danger',
        solid: true
      });
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 1rem;
}
</style>