<template>
  <div>
    <Nav></Nav>
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h1>Table Management</h1>
          <p class="text-muted">
            Manage tables for
            <span v-if="currentFloor">floor: <strong>{{ currentFloor.name }}</strong></span>
            <span v-if="currentSucursal">in <strong>{{ currentSucursal.nombre }}</strong></span>
          </p>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <b-button @click="$router.push('/floors')" variant="outline-secondary" class="mr-2">
            <i class="fas fa-arrow-left"></i> Back to Floors
          </b-button>
          <b-button variant="success" @click="showAddTable" class="mr-2">
            <i class="fas fa-plus"></i> Add Table
          </b-button>
          <b-button variant="outline-primary" @click="refreshTables">
            <i class="fas fa-sync-alt"></i> Refresh
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="isLoading">
        <b-col class="text-center py-5">
          <b-spinner variant="primary"></b-spinner>
          <p class="mt-2">Loading...</p>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col lg="12">
          <!-- Table Editor (when adding/editing) -->
          <TableEditor v-if="showTableForm" :table="selectedTable" :sucursal-id="sucursalId" :floor-id="floorId"
            :is-edit="isEditing" @save="saveTable" @cancel="cancelTableForm" class="mb-4" />

          <!-- Table List -->
          <b-card>
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="mb-0">Tables</h3>
                <b-form-group class="mb-0 d-flex" label-for="table-filter">
                  <b-form-input id="table-filter" v-model="filter" type="search" placeholder="Filter tables..."
                    class="mr-2"></b-form-input>
                  <b-form-checkbox v-model="showInactive" switch>Show Inactive</b-form-checkbox>
                </b-form-group>
              </div>
            </template>

            <b-table :items="filteredTables" :fields="tableFields" :filter="filter" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :busy="isLoading" hover responsive selectable select-mode="single"
              @row-selected="onTableSelected">
              <!-- Table Number Column -->
              <template #cell(table_number)="data">
                <div class="font-weight-bold">{{ data.value }}</div>
              </template>

              <!-- Capacity Column -->
              <template #cell(capacity)="data">
                <div class="text-center">
                  <b-badge pill variant="info">{{ data.value }}</b-badge>
                </div>
              </template>

              <!-- Status Column -->
              <template #cell(status)="data">
                <b-badge :variant="getStatusVariant(data.value)">
                  {{ formatStatus(data.value) }}
                </b-badge>
              </template>

              <!-- Active Column -->
              <template #cell(active)="data">
                <b-badge :variant="data.value ? 'success' : 'danger'">
                  {{ data.value ? 'Active' : 'Inactive' }}
                </b-badge>
              </template>

              <!-- Position Column -->
              <template #cell(position)="data">
                <small>X: {{ data.item.position_x }}, Y: {{ data.item.position_y }}</small>
              </template>

              <!-- QR Hash Column -->
              <template #cell(table_hash)="data">
                <template v-if="data.value">
                  <span class="text-truncate d-inline-block" style="max-width: 120px;">
                    {{ data.value }}
                  </span>
                  <b-button variant="outline-secondary" size="sm"
                    @click.stop="copyToClipboard( data.value)"
                    v-b-tooltip.hover title="Copy hash" class="ml-1">
                    <i class="fas fa-copy"></i>
                  </b-button>
                  <b-button variant="outline-secondary" size="sm"
                    @click.stop="showQRCode(`https://${tenant}.llamamimesero.com?table-hash=${data.value}`, data.item.table_number)"
                    v-b-tooltip.hover title="Generate QR" class="ml-1"><i class="fas fa-qrcode"></i></b-button>
                </template>
                <span v-else class="text-muted">No hash</span>
              </template>

              <!-- Actions Column -->
              <template #cell(actions)="data">
                <b-dropdown text="Status" variant="outline-secondary" size="sm" class="mr-1">
                  <b-dropdown-item v-for="status in tableStatuses" :key="status.value"
                    @click.stop="updateTableStatus(data.item.id, status.value)"
                    :disabled="data.item.status === status.value">
                    <b-badge :variant="getStatusVariant(status.value)" class="mr-2">
                      {{ status.text }}
                    </b-badge>
                    {{ status.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <b-button-group size="sm">
                  <b-button variant="primary" @click.stop="editTable(data.item)" v-b-tooltip.hover title="Edit">
                    <i class="fas fa-edit"></i>
                  </b-button>
                  <b-button variant="danger" @click.stop="confirmDeleteTable(data.item)" v-b-tooltip.hover
                    title="Delete">
                    <i class="fas fa-trash-alt"></i>
                  </b-button>
                </b-button-group>
              </template>

              <!-- Empty State -->
              <template #empty>
                <div class="text-center text-muted py-4">
                  <p>No tables found on this floor</p>
                  <b-button variant="primary" @click="showAddTable">Add Table</b-button>
                </div>
              </template>

              <!-- Loading State -->
              <template #table-busy>
                <div class="text-center my-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>

    </b-container>

    <!-- Confirmation Modal for Delete -->
    <b-modal id="delete-table-modal" ref="deleteModal" title="Confirm Delete" @ok="confirmDeleteAction"
      ok-variant="danger" ok-title="Delete" cancel-title="Cancel">
      <p>Are you sure you want to delete table "{{ tableToDelete ? tableToDelete.table_number : '' }}"?</p>
    </b-modal>
    <b-modal id="qr-code-modal" ref="qrModal" centered hide-footer title="Table QR Code">
      <div class="text-center">
        <h5 class="mb-3">Table #{{ qrTableNumber }}</h5>
        <div class="mb-3" v-if="qrCodeUrl">
          <img :src="qrCodeUrl" alt="QR Code" class="img-fluid" />
        </div>
        <div v-else class="text-center py-4">
          <b-spinner variant="primary"></b-spinner>
          <p class="mt-2">Generating QR code...</p>
        </div>
        <p class="small text-muted mb-3">Scan this code to call a waiter for table #{{ qrTableNumber }}</p>
        <div class="d-flex justify-content-center">
          <b-button variant="primary" @click="downloadQRCode" class="mr-2" :disabled="!qrCodeUrl">
            <i class="fas fa-download"></i> Download
          </b-button>
          <b-button variant="outline-secondary" @click="$refs.qrModal.hide()">
            Close
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';
import TableEditor from '@/components/TableEditor.vue';
import tableService from '@/services/tableService';
import axios from 'axios';
export default {
  name: 'TableManagement',
  components: {
    Nav,
    TableEditor
  },

  props: {
    sucursalId: {
      type: [Number, String],
      required: true
    },
    floorId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      qrCodeUrl: null,
      qrTableNumber: '',
      qrCodeText: '',
      isLoading: true,
      tables: [],
      currentFloor: null,
      currentSucursal: null,
      selectedTable: null,
      tableToDelete: null,
      showTableForm: false,
      isEditing: false,
      filter: '',
      showInactive: false,
      sortBy: 'table_number',
      sortDesc: false,
      tableFields: [
        { key: 'table_number', label: 'Table #', sortable: true },
        { key: 'capacity', label: 'Capacity', sortable: true, class: 'text-center' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'active', label: 'Active', sortable: true },
        { key: 'position', label: 'Position' },
        { key: 'table_hash', label: 'QR Hash' },
        { key: 'actions', label: 'Actions' }
      ],
      tableStatuses: [
        { value: 'available', text: 'Available' },
        { value: 'occupied', text: 'Occupied' },
        { value: 'reserved', text: 'Reserved' },
        { value: 'maintenance', text: 'Maintenance' }
      ]
    };
  },
  computed: {
    tenant() {
      return this.$store.getters["server/tenant"];
    },
    filteredTables() {
      if (!this.showInactive) {
        return this.tables.filter(table => table.active);
      }
      return this.tables;
    }
  },
  async created() {
    await this.loadData();
  },
  methods: {
    async showQRCode(url, tableNumber) {
      this.qrCodeUrl = null;
      this.qrTableNumber = tableNumber;
      this.qrCodeText = url;
      this.$refs.qrModal.show();

      // Generate QR code using a service
      try {
        // Using QuickChart API for QR code generation
        const encodedUrl = encodeURIComponent(url);
        const encodedCaption = encodeURIComponent(`${this.currentSucursal.nombre} Mesa # ${tableNumber}`);
        this.qrCodeUrl = `https://quickchart.io/qr?format=png&margin=3&size=300&caption=${encodedCaption}&text=${encodedUrl}`;
      } catch (error) {
        console.error('Failed to generate QR code', error);
        this.showErrorToast('Failed to generate QR code', error);
      }
    },

    downloadQRCode() {
      if (!this.qrCodeUrl) return;
      // Create a temporary link to download the image
      const link = document.createElement('a');
      link.href = this.qrCodeUrl;
      link.download = `table-${this.qrTableNumber}-qr.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showSuccessToast('QR code downloaded');
    },

    async loadData() {
      this.isLoading = true;
      try {
        // Load floor data
        const floorResponse = await tableService.getFloorById(this.floorId);
        this.currentFloor = floorResponse.data;

        // Load tables data
        const tablesResponse = await tableService.getTablesByFloor(this.floorId);
        this.tables = tablesResponse.data;

        // Load sucursal data
        try {
          // Adjust this endpoint to match your API
          const sucursalResponse = await axios.get(`/sucursal/${this.sucursalId}`);
          this.currentSucursal = sucursalResponse.data;
        } catch (error) {
          console.error('Error loading sucursal:', error);
          // Non-critical error, we can continue without sucursal info
        }
      } catch (error) {
        this.showErrorToast('Failed to load floor data', error);
      } finally {
        this.isLoading = false;
      }
    },

    async refreshTables() {
      try {
        const response = await tableService.getTablesByFloor(this.floorId);
        this.tables = response.data;
        this.showSuccessToast('Tables refreshed');
      } catch (error) {
        this.showErrorToast('Failed to refresh tables', error);
      }
    },

    onTableSelected(items) {
      this.selectedTable = items.length > 0 ? items[0] : null;
    },

    showAddTable() {
      this.isEditing = false;
      this.selectedTable = {
        sucursal_id: this.sucursalId,
        floor_id: this.floorId,
        table_number: '',
        capacity: 4,
        status: 'available',
        position_x: 100,
        position_y: 100,
        active: true
      };
      this.showTableForm = true;
    },

    editTable(table) {
      this.selectedTable = { ...table };
      this.isEditing = true;
      this.showTableForm = true;
    },

    async saveTable(tableData) {
      if (this.isEditing) {
        tableService.updateTable(tableData.id, tableData).then(() => {
          this.showSuccessToast('Table updated successfully');
        }).catch(error => {
          this.showErrorToast('Failed to update table', error.response);
        });
      } else {
        tableService.createTable(tableData).then(response => {
          this.tables.push(response.data);
          this.showSuccessToast('Table created successfully');
        }).catch(error => {
          this.showErrorToast('Failed to create table', error);
        });
      }
      // Hide the form and unselect table
      this.showTableForm = false;
      this.selectedTable = null;

    },

    cancelTableForm() {
      this.showTableForm = false;
      this.selectedTable = null;
    },

    confirmDeleteTable(table) {
      this.tableToDelete = table;
      this.$refs.deleteModal.show();
    },

    async confirmDeleteAction() {
      if (!this.tableToDelete) return;

      try {
        await tableService.deleteTable(this.tableToDelete.id);
        this.showSuccessToast('Table deleted successfully');

        // Remove the table from the tables array
        this.tables = this.tables.filter(t => t.id !== this.tableToDelete.id);

        // Unselect the table if it was selected
        if (this.selectedTable && this.selectedTable.id === this.tableToDelete.id) {
          this.selectedTable = null;
        }
      } catch (error) {
        this.showErrorToast('Failed to delete table', error);
      } finally {
        this.tableToDelete = null;
      }
    },

    async updateTableStatus(tableId, status) {
      try {
        await tableService.updateTableStatus(tableId, status);

        // Update the status in the tables array
        const index = this.tables.findIndex(t => t.id === tableId);
        if (index !== -1) {
          this.tables[index] = { ...this.tables[index], status };
        }

        this.showSuccessToast(`Table status changed to ${this.formatStatus(status)}`);
      } catch (error) {
        this.showErrorToast('Failed to update table status', error);
      }
    },

    getStatusVariant(status) {
      switch (status) {
        case 'available': return 'success';
        case 'occupied': return 'danger';
        case 'reserved': return 'warning';
        case 'maintenance': return 'secondary';
        default: return 'light';
      }
    },

    formatStatus(status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },

    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$bvToast.toast('Hash copied to clipboard', {
            title: 'Copied!',
            variant: 'success',
            solid: true,
            autoHideDelay: 1500
          });
        },
        () => {
          this.$bvToast.toast('Failed to copy to clipboard', {
            title: 'Error',
            variant: 'danger',
            solid: true
          });
        }
      );
    },

    showSuccessToast(message) {
      this.$bvToast.toast(message, {
        title: 'Success',
        variant: 'success',
        solid: true
      });
    },

    showErrorToast(message, error) {
      console.error(message, error);
      this.$bvToast.toast(error.response?.data?.message || error.message || 'An error occurred', {
        title: message,
        variant: 'danger',
        solid: true
      });
    }
  }
};
</script>

<style scoped>
.card {
  margin-bottom: 1rem;
}
</style>