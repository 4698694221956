<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col>
          <h1>Reporte de Cambio de Inventario</h1>
        </b-col>
      </b-row>

      <!-- Selección de Parámetros del Reporte -->
      <b-row>
        <b-col lg="3" md="6" sm="12">
          <b-form-group label="Sucursal:">
            <b-form-select v-model="selectedSucursal" :options="sucursals" @change="loadCountGroups">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccionar Sucursal</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6" sm="12">
          <b-form-group label="Conteo Inicial:">
            <b-form-select v-model="selectedInitialCount" :options="countGroups" :disabled="!selectedSucursal">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccionar Conteo Inicial</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6" sm="12">
          <b-form-group label="Conteo Final:">
            <b-form-select v-model="selectedEndCount" :options="countGroups" :disabled="!selectedSucursal">
              <template #first>
                <b-form-select-option :value="null" disabled>Seleccionar Conteo Final</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6" sm="12">
          <b-form-group label="Producto en seguimiento:">
            <b-form-checkbox v-model="showOnlyTrackable" switch>
              {{ showOnlyTrackable ? 'Sí' : 'No' }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col sm="12" class="d-flex justify-content-end">
          <b-button variant="primary" @click="generateReport" :disabled="!canGenerateReport">
            Generar Reporte
          </b-button>
          <b-button variant="success" class="ml-2" @click="exportToExcel" :disabled="!reportData.length">
            Exportar a Excel
          </b-button>
        </b-col>
      </b-row>

      <!-- Resumen del Reporte -->
      <b-row v-if="reportData.length" class="mb-3">
        <b-col sm="12">
          <b-card bg-variant="light">
            <b-row>
              <b-col md="3" sm="6">
                <div class="summary-item">
                  <div class="summary-label">Valor Inventario Inicial:</div>
                  <div class="summary-value">${{ formatNumber(totalInitialCost) }}</div>
                </div>
              </b-col>
              <b-col md="3" sm="6">
                <div class="summary-item">
                  <div class="summary-label">Valor de Compras:</div>
                  <div class="summary-value">${{ formatNumber(totalPurchases) }}</div>
                </div>
              </b-col>
              <b-col md="3" sm="6">
                <div class="summary-item">
                  <div class="summary-label">Insumo para Ventas:</div>
                  <div class="summary-value">${{ formatNumber(totalCustomerSales) }}</div>
                </div>
              </b-col>
              <b-col md="3" sm="6">
                <div class="summary-item">
                  <div class="summary-label">Valor Inventario Final:</div>
                  <div class="summary-value">${{ formatNumber(totalEndCost) }}</div>
                </div>
              </b-col>
            </b-row>

            <!-- Indicador de Diferencia Total de Dinero -->
            <b-row class="mt-3">
              <b-col sm="12">
                <div class="total-difference"
                  :class="{ 'text-danger': totalDifference > 0, 'text-success': totalDifference < 0 }">
                  <div class="total-difference-label">Diferencia Total de Valor:</div>
                  <div class="total-difference-value">${{ formatNumber(totalDifference) }}</div>
                  <div class="total-difference-percent" v-if="totalInitialCost > 0">
                    ({{ formatNumber((totalDifference / totalInitialCost) * 100) }}% del inventario inicial)
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!-- Controles de Filtro -->
      <b-row v-if="reportData.length" class="mb-3">
        <b-col md="6" sm="12">
          <b-form-group label="Buscar:">
            <b-form-input v-model="filter" placeholder="Buscar por nombre, categoría, etc."></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group label="Mostrar solo productos con discrepancias:">
            <b-form-checkbox v-model="showOnlyDiscrepancies" switch>
              {{ showOnlyDiscrepancies ? 'Sí' : 'No' }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6">
          <b-form-group label="Elementos por página:">
            <b-form-select v-model="perPage" :options="[10, 25, 50, 100]"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Tabla del Reporte -->
      <b-row>
        <b-col sm="12">
          <b-overlay :show="loading" rounded="sm">
            <b-table v-if="reportData.length" :items="filteredReportData" :fields="fields" :per-page="perPage"
              :current-page="currentPage" :filter="filter" striped hover responsive show-empty small stacked="md"
              :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <!-- Formato personalizado para columnas numéricas -->
              <template #cell(supply_price)="data">
                ${{ formatNumber(data.value) }}
              </template>

              <template #cell(initial_qty)="data">
                {{ formatNumber(data.value) }} {{ data.item.supply_unit }}
              </template>

              <template #cell(initial_cost)="data">
                ${{ formatNumber(data.value) }}
              </template>

              <template #cell(stock_movements_qty)="data">
                {{ formatNumber(data.value) }} {{ data.item.supply_unit }}
              </template>

              <template #cell(stock_movements_total)="data">
                ${{ formatNumber(data.value) }}
              </template>

              <template #cell(purchase_order_qty)="data">
                {{ formatNumber(data.value) }} {{ data.item.supply_unit }}
              </template>

              <template #cell(purchase_order_total)="data">
                ${{ formatNumber(data.value) }}
              </template>

              <template #cell(customer_out_qty)="data">
                {{ formatNumber(data.value) }} {{ data.item.supply_unit }}
              </template>

              <template #cell(customer_out_total)="data">
                ${{ formatNumber(data.value) }}
              </template>

              <template #cell(end_qty)="data">
                {{ formatNumber(data.value) }} {{ data.item.supply_unit }}
              </template>

              <template #cell(end_cost)="data">
                ${{ formatNumber(data.value) }}
              </template>

              <template #cell(difference_qty)="data">
                <span :class="{ 'text-danger': data.value < 0, 'text-success': data.value > 0 }">
                  {{ formatNumber(data.value) }} {{ data.item.supply_unit }}
                </span>
              </template>

              <template #cell(difference_total)="data">
                <span :class="{ 'text-danger': data.value > 0 }">
                  ${{ formatNumber(data.value) }}
                </span>
              </template>
            </b-table>

            <div v-if="!reportData.length && !loading" class="text-center p-5">
              <p>No hay datos disponibles. Por favor seleccione parámetros y genere un reporte.</p>
            </div>
          </b-overlay>
        </b-col>
      </b-row>

      <!-- Paginación -->
      <b-row v-if="reportData.length">
        <b-col sm="12" class="mt-3">
          <b-pagination v-model="currentPage" :total-rows="filteredReportData.length" :per-page="perPage" align="center"
            aria-controls="inventory-table"></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import axios from "axios";
import * as XLSX from 'xlsx';

export default {
  name: "InventoryChangeReport",
  components: { Nav },
  data() {
    return {
      // Parámetros de selección
      selectedSucursal: null,
      selectedInitialCount: null,
      selectedEndCount: null,

      // Fuentes de datos
      sucursals: [],
      countGroups: [],
      reportData: [],

      // Controles de tabla
      filter: "",
      perPage: 25,
      currentPage: 1,
      sortBy: 'supply_name',
      sortDesc: false,
      showOnlyDiscrepancies: false,
      showOnlyTrackable:false,
      // Estado de UI
      loading: false,

      // Definiciones de campos de la tabla
      fields: [
        { key: 'supply_name', label: 'Insumo', sortable: true },
        { key: 'supply_category', label: 'Categoría', sortable: true },
        { key: 'supply_unit', label: 'Unidad', sortable: true },
        { key: 'supply_price', label: 'Precio Unitario', sortable: true },
        { key: 'initial_qty', label: 'Cant. Inicial', sortable: true },
        { key: 'initial_cost', label: 'Costo Inicial', sortable: true },
        { key: 'stock_movements_qty', label: 'Cant. Movimientos', sortable: true },
        { key: 'stock_movements_total', label: 'Costo Movimientos', sortable: true },
        { key: 'purchase_order_qty', label: 'Cant. Compras', sortable: true },
        { key: 'purchase_order_total', label: 'Costo Compras', sortable: true },
        { key: 'customer_out_qty', label: 'Cant. Ventas', sortable: true },
        { key: 'customer_out_total', label: 'Costo Ventas', sortable: true },
        { key: 'end_qty', label: 'Cant. Final', sortable: true },
        { key: 'end_cost', label: 'Costo Final', sortable: true },
        { key: 'theoretical_qty', label: 'Cant. Teorica', sortable: true },
        { key: 'theoretical_total', label: 'Costo Teorico', sortable: true },
        { key: 'difference_qty', label: 'Dif. Cantidad', sortable: true },
        { key: 'difference_total', label: 'Dif. Costo', sortable: true }
      ]
    };
  },
  computed: {
    canGenerateReport() {
      return this.selectedSucursal && this.selectedInitialCount && this.selectedEndCount;
    },
    filteredReportData() {
      if (!this.showOnlyDiscrepancies) {
        return this.reportData;
      }

      return this.reportData.filter(item => {
        return Math.abs(item.difference_qty) > 0.001 || Math.abs(item.difference_total) > 0.01;
      });
    },
    totalInitialCost() {
      return this.reportData.reduce((sum, item) => sum + item.initial_cost, 0);
    },
    totalPurchases() {
      return this.reportData.reduce((sum, item) => sum + item.purchase_order_total, 0);
    },
    totalCustomerSales() {
      return this.reportData.reduce((sum, item) => sum + item.customer_out_total, 0);
    },
    totalEndCost() {
      return this.reportData.reduce((sum, item) => sum + item.end_cost, 0);
    },
    totalDifference() {
      return this.reportData.reduce((sum, item) => sum + item.difference_total, 0);
    }
  },
  mounted() {
    this.loadSucursals();
  },
  methods: {
    loadSucursals() {
      axios.get("/sucursals")
        .then(response => {
          this.sucursals = response.data.map(sucursal => ({
            value: sucursal.id,
            text: sucursal.nombre
          }));
        })
        .catch(error => {
          this.makeToast("Error", "Error al cargar sucursales: " + error.message, "danger");
        });
    },
    loadCountGroups() {
      if (!this.selectedSucursal) return;

      this.loading = true;
      axios.get(`/sucursal/${this.selectedSucursal}/stock/physicalcount/groups`)
        .then(response => {
          this.countGroups = response.data.filter(gp => gp.state != "canceled").map(group => ({
            value: group.id,
            text: `${group.name} (${group.count_date.substring(0, 10)})`
          }));
          this.loading = false;
        })
        .catch(error => {
          this.makeToast("Error", "Error al cargar grupos de conteo: " + error.message, "danger");
          this.loading = false;
        });
    },
    generateReport() {
      if (!this.canGenerateReport) return;

      this.loading = true;
      axios.get(`/report/inventory/change/${this.selectedSucursal}/${this.selectedInitialCount}/${this.selectedEndCount}?trackable_supplies=`+this.showOnlyTrackable)
        .then(response => {
          console.log(response.data);
          if (response.data == null) {
            this.currentPage = 1;
            this.loading = false;
            this.reportData = [];
            console.log("No hay datos para mostrar");
          }else{
            this.reportData = response.data;
            this.currentPage = 1;
            this.loading = false;
          }
        })
        .catch(error => {
          this.makeToast("Error", "Error al generar reporte: " + error.message, "danger");
          this.loading = false;
        });
    },
    exportToExcel() {
      if (!this.reportData.length) return;

      // Crear una hoja de cálculo
      const worksheet = XLSX.utils.json_to_sheet(this.reportData.map(item => ({
        'ID Insumo': item.supply_id,
        'Nombre Insumo': item.supply_name,
        'Categoría': item.supply_category,
        'Unidad': item.supply_unit,
        'Precio Unitario': item.supply_price,
        'Cantidad Inicial': item.initial_qty,
        'Costo Inicial': item.initial_cost,
        'Cantidad Movimientos': item.stock_movements_qty,
        'Costo Movimientos': item.stock_movements_total,
        'Cantidad Compras': item.purchase_order_qty,
        'Costo Compras': item.purchase_order_total,
        'Cantidad Ventas': item.customer_out_qty,
        'Costo Ventas': item.customer_out_total,
        'Inventario Teórico': item.theoretical_qty,
        'Costo Teórico': item.theoretical_total,
        'Cantidad Final': item.end_qty,
        'Costo Final': item.end_cost,
        'Diferencia Cantidad': item.difference_qty,
        'Diferencia Costo': item.difference_total
      })));

      // Crear un libro de trabajo
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Reporte de Inventario");

      // Generar archivo Excel
      const initialDateText = this.getCountGroupDate(this.selectedInitialCount);
      const endDateText = this.getCountGroupDate(this.selectedEndCount);
      const fileName = `Reporte_Inventario_${initialDateText}_a_${endDateText}.xlsx`;

      XLSX.writeFile(workbook, fileName);
    },
    getCountGroupDate(countGroupId) {
      const group = this.countGroups.find(g => g.value === countGroupId);
      if (group) {
        // Extraer solo la parte de la fecha (asumiendo formato "Nombre (YYYY-MM-DD)")
        const match = group.text.match(/\(([^)]+)\)/);
        return match ? match[1] : "desconocido";
      }
      return "desconocido";
    },
    formatNumber(value) {
      if (value === null || value === undefined) return '0';
      return Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    makeToast(title, content, variant) {
      this.$bvToast.toast(content, {
        title,
        variant,
        autoHideDelay: 5000,
        appendToast: true,
      });
    }
  }
};
</script>

<style scoped>
.summary-item {
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  margin-bottom: 10px;
}

.summary-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #495057;
}

.summary-value {
  font-size: 1.2rem;
  font-weight: 600;
  color: #343a40;
}

.total-difference {
  padding: 12px;
  background-color: #e9ecef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.total-difference-label {
  font-weight: bold;
  font-size: 1.1rem;
  margin-right: 10px;
}

.total-difference-value {
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 10px;
}

.total-difference-percent {
  font-size: 0.9rem;
  font-style: italic;
}

.text-danger .total-difference-value,
.text-danger .total-difference-percent {
  color: #dc3545;
}

.text-success .total-difference-value,
.text-success .total-difference-percent {
  color: #28a745;
}

/* Ajustes para tabla responsive */
@media (max-width: 768px) {
  .b-table-stacked-md th {
    display: none;
  }
}
</style>