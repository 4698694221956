<template>
  <div>
    <b-form @submit.prevent="submitForm">
      <b-row>
        <!-- Columna de Información General -->
        <b-col md="6">
          <b-card title="Información General" class="mb-4">
            <b-form-group label="Nombre del Programa:">
              <b-form-input 
                v-model="form.name" 
                placeholder="Ej: Descuento Clientes VIP"
                required
              ></b-form-input>
            </b-form-group>
            
            <b-form-group label="Tipo de Programa:">
              <b-form-select v-model="form.program_type">
                <b-form-select-option value="discount">Descuento</b-form-select-option>
                <b-form-select-option value="buy_x_get_y">Compra X, Obtén Y</b-form-select-option>
                <b-form-select-option value="free_product">Producto Gratis</b-form-select-option>
                <b-form-select-option value="free_products">Productos Gratis (Múltiples)</b-form-select-option>
                <b-form-select-option value="coupons">Cupón de Descuento</b-form-select-option>
              </b-form-select>
            </b-form-group>
            
            <b-form-group label="Producto para aplicar descuento:">
              <b-form-input 
                v-model="form.discount_line_product_id" 
                type="number"
                placeholder="ID del producto"
                required
              ></b-form-input>
              <small class="text-muted">Este producto se utilizará para aplicar el descuento en el ticket</small>
            </b-form-group>
            
            <b-form-group>
              <b-form-checkbox v-model="form.active">
                Activo
              </b-form-checkbox>
            </b-form-group>
            
            <b-form-group>
              <b-form-checkbox v-model="form.limit_usage">
                Limitar uso (solo una promoción por ticket)
              </b-form-checkbox>
            </b-form-group>
            
            <b-form-group>
              <b-form-checkbox v-model="form.portal_visible">
                Visible en Portal
              </b-form-checkbox>
            </b-form-group>
            
            <b-form-group>
              <b-form-checkbox v-model="form.require_extra">
                Requiere información adicional
              </b-form-checkbox>
            </b-form-group>
            
            <b-form-group v-if="form.require_extra" label="Descripción del campo adicional:">
              <b-form-input 
                v-model="form.extra_description" 
                placeholder="Ej: Número de mesa"
              ></b-form-input>
            </b-form-group>
          </b-card>
        </b-col>
        
        <!-- Columna de Reglas y Recompensas -->
        <b-col md="6">
          <!-- Reglas -->
          <b-card title="Reglas de Obtención de Puntos" class="mb-4">
            <b-card bg-variant="light" class="mb-3">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Modo de Recompensa:">
                    <b-form-select v-model="currentRule.reward_pointmode">
                      <b-form-select-option value="order">Por Orden</b-form-select-option>
                      <b-form-select-option value="money">Por Monto de Dinero</b-form-select-option>
                      <b-form-select-option value="unit">Por Unidad de Producto</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                
                <b-col md="6">
                  <b-form-group label="Modo de Activación:">
                    <b-form-select v-model="currentRule.mode">
                      <b-form-select-option value="with_code">Con Código</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <b-form-group v-if="currentRule.mode === 'with_code'" label="Código de Promoción:">
                <b-form-input 
                  v-model="currentRule.code"
                  placeholder="Ej: VERANO2023"
                ></b-form-input>
              </b-form-group>
              
              <b-form-group v-if="currentRule.reward_pointmode === 'money'" label="Monto Mínimo:">
                <b-form-input 
                  v-model.number="currentRule.minimum_amount"
                  type="number"
                  min="0"
                ></b-form-input>
              </b-form-group>
              
              <b-form-group v-if="currentRule.reward_pointmode === 'unit'" label="Cantidad Mínima:">
                <b-form-input 
                  v-model.number="currentRule.minimum_qty"
                  type="number"
                  min="1"
                ></b-form-input>
              </b-form-group>
              
              <b-form-group label="Puntos a Otorgar:">
                <b-form-input 
                  v-model.number="currentRule.reward_point_amount"
                  type="number"
                  min="1"
                ></b-form-input>
              </b-form-group>
              
              <div class="d-flex justify-content-end">
                <b-button variant="primary" size="sm" @click="addRule">
                  Añadir Regla
                </b-button>
              </div>
            </b-card>
            
            <div v-if="form.rules.length > 0">
              <h6>Reglas Añadidas</h6>
              <b-list-group class="mb-2">
                <b-list-group-item v-for="(rule, index) in form.rules" :key="index" class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-bold">
                      {{ rule.mode === 'with_code' ? `Código: ${rule.code}` : 'Regla Automática' }}
                    </div>
                    <small>
                      {{ rule.reward_pointmode === 'order' ? 'Puntos por orden' : 
                         rule.reward_pointmode === 'money' ? `Puntos por monto (min: $${rule.minimum_amount})` :
                         rule.reward_pointmode === 'unit' ? `Puntos por unidad (min: ${rule.minimum_qty})` : '' }}
                      | Otorga {{ rule.reward_point_amount }} punto(s)
                    </small>
                  </div>
                  <b-button variant="outline-danger" size="sm" @click="removeRule(index)">
                    <i class="fas fa-trash"></i>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-else class="text-center text-muted my-3">
              No hay reglas añadidas
            </div>
          </b-card>
          
          <!-- Recompensas -->
          <b-card title="Recompensas">
            <b-card bg-variant="light" class="mb-3">
              <b-form-group label="Tipo de Recompensa:">
                <b-form-select v-model="currentReward.reward_type">
                  <b-form-select-option value="discount">Descuento</b-form-select-option>
                  <b-form-select-option value="free_product">Producto Gratis</b-form-select-option>
                </b-form-select>
              </b-form-group>
              
              <template v-if="currentReward.reward_type === 'discount'">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Modo de Descuento:">
                      <b-form-select v-model="currentReward.discount_mode">
                        <b-form-select-option value="percent">Porcentaje</b-form-select-option>
                        <b-form-select-option value="per_order">Por Orden</b-form-select-option>
                        <b-form-select-option value="per_point">Por Punto</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  
                  <b-col md="6">
                    <b-form-group label="Aplicable a:">
                      <b-form-select v-model="currentReward.discount_applicability">
                        <b-form-select-option value="order">Toda la Orden</b-form-select-option>
                        <b-form-select-option value="specific">Productos Específicos</b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                
                <b-form-group :label="currentReward.discount_mode === 'percent' ? 'Porcentaje de Descuento:' : 'Monto de Descuento:'">
                  <b-form-input 
                    v-model.number="currentReward.discount" 
                    type="number" 
                    min="0" 
                    :step="currentReward.discount_mode === 'percent' ? '1' : '0.01'"
                    required
                  ></b-form-input>
                </b-form-group>
                
                <b-form-group label="Monto Máximo de Descuento:">
                  <b-form-input 
                    v-model.number="currentReward.discount_max_amount" 
                    type="number" 
                    min="0" 
                    step="0.01"
                  ></b-form-input>
                  <small class="text-muted">0 = Sin límite</small>
                </b-form-group>
              </template>
              
              <template v-if="currentReward.reward_type === 'free_product'">
                <b-form-group label="Producto a Regalar:">
                  <b-form-input 
                    v-model.number="currentReward.reward_product_id" 
                    type="number" 
                    placeholder="ID del producto"
                    required
                  ></b-form-input>
                </b-form-group>
                
                <b-form-group label="Cantidad a Regalar:">
                  <b-form-input 
                    v-model.number="currentReward.reward_product_qty" 
                    type="number" 
                    min="1" 
                    step="1"
                    required
                  ></b-form-input>
                </b-form-group>
              </template>
              
              <b-form-group label="Descripción de la Recompensa:">
                <b-form-input 
                  v-model="currentReward.description" 
                  placeholder="Ej: 15% de descuento en la cuenta"
                  required
                ></b-form-input>
              </b-form-group>
              
              <b-form-group label="Puntos Requeridos:">
                <b-form-input 
                  v-model.number="currentReward.required_points" 
                  type="number" 
                  min="1"
                  required
                ></b-form-input>
              </b-form-group>
              
              <div class="d-flex justify-content-end">
                <b-button variant="primary" size="sm" @click="addReward">
                  Añadir Recompensa
                </b-button>
              </div>
            </b-card>
            
            <div v-if="form.rewards.length > 0">
              <h6>Recompensas Añadidas</h6>
              <b-list-group class="mb-2">
                <b-list-group-item v-for="(reward, index) in form.rewards" :key="index" class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="font-weight-bold">{{ reward.description }}</div>
                    <small>
                      {{ reward.reward_type === 'discount' ? 
                        `${reward.discount_mode === 'percent' ? `${reward.discount}%` : 
                         reward.discount_mode === 'per_order' ? `$${reward.discount}` : 
                         `$${reward.discount} por punto`} de descuento en ${reward.discount_applicability === 'order' ? 'toda la orden' : 'productos específicos'}` : 
                        `${reward.reward_product_qty} ${reward.reward_product_qty > 1 ? 'unidades' : 'unidad'} del producto #${reward.reward_product_id}` }}
                      | Requiere {{ reward.required_points }} punto(s)
                    </small>
                  </div>
                  <b-button variant="outline-danger" size="sm" @click="removeReward(index)">
                    <i class="fas fa-trash"></i>
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div v-else class="text-center text-muted my-3">
              No hay recompensas añadidas
            </div>
          </b-card>
        </b-col>
      </b-row>
      
      <div class="d-flex justify-content-between mt-4 pt-3 border-top">
        <b-button variant="outline-secondary" @click="$emit('cancel')">
          Cancelar
        </b-button>
        <b-button type="submit" variant="primary">
          {{ isEditing ? 'Guardar Cambios' : 'Crear Programa' }}
        </b-button>
      </div>
    </b-form>
</div>
</template>

<script>
import axios from "axios";

export default {
  name: "ProgramForm",
  props: {
    program: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isEditing: false,
      form: {
        name: "",
        program_type: "discount",
        max_usage: 0,
        active: true,
        limit_usage: false,
        portal_visible: false,
        require_extra: false,
        extra_description: "",
        discount_line_product_id: "",
        rules: [],
        rewards: []
      },
      currentRule: {
        reward_pointmode: "order",
        mode: "with_code",
        code: "",
        minimum_amount: 0,
        minimum_qty: null,
        active: true,
        reward_point_amount: 1
      },
      currentReward: {
        reward_type: "discount",
        discount_mode: "percent",
        discount_applicability: "order",
        description: "",
        discount_max_amount: 0,
        active: true,
        discount: 0,
        required_points: 1,
        reward_product_id: null,
        reward_product_qty: null
      }
    };
  },
  created() {
    this.initializeForm();
  },
  watch: {
    program() {
      this.initializeForm();
    }
  },
  methods: {
    initializeForm() {
      if (this.program) {
        this.isEditing = true;
        // Copiar las propiedades principales
        this.form = {
          id: this.program.id,
          name: this.program.name || "",
          program_type: this.program.program_type || "discount",
          max_usage: this.program.max_usage || 0,
          active: this.program.active !== undefined ? this.program.active : true,
          limit_usage: this.program.limit_usage !== undefined ? this.program.limit_usage : false,
          portal_visible: this.program.portal_visible !== undefined ? this.program.portal_visible : false,
          require_extra: this.program.require_extra !== undefined ? this.program.require_extra : false,
          extra_description: this.program.extra_description || "",
          discount_line_product_id: this.program.discount_line_product_id || "",
          rules: [],
          rewards: []
        };
        
        // Copiar reglas si existen
        if (this.program.rules && this.program.rules.length > 0) {
          this.form.rules = JSON.parse(JSON.stringify(this.program.rules));
        }
        
        // Copiar recompensas si existen
        if (this.program.rewards && this.program.rewards.length > 0) {
          this.form.rewards = JSON.parse(JSON.stringify(this.program.rewards));
        }
      } else {
        this.isEditing = false;
        // Reiniciar el formulario
        this.form = {
          name: "",
          program_type: "discount",
          max_usage: 0,
          active: true,
          limit_usage: false,
          portal_visible: false,
          require_extra: false,
          extra_description: "",
          discount_line_product_id: "",
          rules: [],
          rewards: []
        };
      }
    },
    
    addRule() {
      // Validar código único
      if (this.currentRule.mode === 'with_code' && !this.currentRule.code.trim()) {
        this.makeToast("Error", "El código de promoción no puede estar vacío", "danger");
        return;
      }
      
      if (this.currentRule.mode === 'with_code' && 
          this.form.rules.some(r => r.code === this.currentRule.code)) {
        this.makeToast("Error", "El código de promoción ya existe", "danger");
        return;
      }
      
      // Añadir la regla al array
      this.form.rules.push({...this.currentRule, id: Date.now()});
      
      // Resetear el formulario de reglas
      this.currentRule = {
        reward_pointmode: "order",
        mode: "with_code",
        code: "",
        minimum_amount: 0,
        minimum_qty: null,
        active: true,
        reward_point_amount: 1
      };
    },
    
    removeRule(index) {
      this.form.rules.splice(index, 1);
    },
    
    addReward() {
      // Validaciones básicas
      if (this.currentReward.reward_type === 'discount' && 
          (!this.currentReward.discount || this.currentReward.discount <= 0)) {
        this.makeToast("Error", "El descuento debe ser mayor a 0", "danger");
        return;
      }
      
      if (this.currentReward.reward_type === 'free_product' && 
          (!this.currentReward.reward_product_id || !this.currentReward.reward_product_qty)) {
        this.makeToast("Error", "Debe seleccionar un producto y cantidad para la recompensa", "danger");
        return;
      }
      
      if (!this.currentReward.description.trim()) {
        this.makeToast("Error", "La descripción de la recompensa no puede estar vacía", "danger");
        return;
      }
      
      // Añadir la recompensa al array
      this.form.rewards.push({...this.currentReward, id: Date.now()});
      
      // Resetear el formulario de recompensas
      this.currentReward = {
        reward_type: "discount",
        discount_mode: "percent",
        discount_applicability: "order",
        description: "",
        discount_max_amount: 0,
        active: true,
        discount: 0,
        required_points: 1,
        reward_product_id: null,
        reward_product_qty: null
      };
    },
    
    removeReward(index) {
      this.form.rewards.splice(index, 1);
    },
    
    async submitForm() {
      // Validaciones
      if (!this.form.name.trim()) {
        this.makeToast("Error", "El nombre del programa no puede estar vacío", "danger");
        return;
      }
      
      if (!this.form.discount_line_product_id) {
        this.makeToast("Error", "Debe seleccionar un producto para aplicar el descuento", "danger");
        return;
      }
      
      if (this.form.rules.length === 0) {
        this.makeToast("Error", "Debe añadir al menos una regla al programa", "danger");
        return;
      }
      
      if (this.form.rewards.length === 0) {
        this.makeToast("Error", "Debe añadir al menos una recompensa al programa", "danger");
        return;
      }
      
      try {
        // Crear o actualizar el programa
        let response;
        const programData = {
          name: this.form.name,
          max_usage: this.form.max_usage,
          program_type: this.form.program_type,
          active: this.form.active,
          limit_usage: this.form.limit_usage,
          portal_visible: this.form.portal_visible,
          require_extra: this.form.require_extra,
          extra_description: this.form.extra_description,
          discount_line_product_id: parseInt(this.form.discount_line_product_id)
        };
        
        if (this.isEditing) {
          // Actualizar programa existente
          response = await axios.put(`/loyalty/program/${this.form.id}`, programData);
        } else {
          // Crear nuevo programa
          response = await axios.post("/loyalty/program", programData);
        }
        
        const programId = this.isEditing ? this.form.id : response.data.id;
        
        // Crear/Actualizar reglas
        // Nota: En un caso real, deberíamos también manejar actualización y eliminación de reglas existentes
        for (const rule of this.form.rules) {
          const ruleData = {
            program_id: programId,
            reward_pointmode: rule.reward_pointmode,
            mode: rule.mode,
            code: rule.code,
            minimum_amount: rule.minimum_amount,
            minimum_qty: rule.minimum_qty,
            active: rule.active,
            reward_point_amount: rule.reward_point_amount
          };
          
          if (rule.id && rule.id > 0 && typeof rule.id === 'number') {
            // Actualizar regla existente
            await axios.put(`/loyalty/program/${programId}/rule/${rule.id}`, ruleData);
          } else {
            // Crear nueva regla
            await axios.post(`/loyalty/program/${programId}/rule`, ruleData);
          }
        }
        
        // Crear/Actualizar recompensas
        for (const reward of this.form.rewards) {
          const rewardData = {
            program_id: programId,
            reward_type: reward.reward_type,
            discount_mode: reward.discount_mode,
            discount_applicability: reward.discount_applicability,
            description: reward.description,
            discount_max_amount: reward.discount_max_amount,
            active: reward.active,
            discount: reward.discount,
            required_points: reward.required_points,
            reward_product_id: reward.reward_product_id,
            reward_product_qty: reward.reward_product_qty
          };
          
          if (reward.id && reward.id > 0 && typeof reward.id === 'number') {
            // Actualizar recompensa existente
            await axios.put(`/loyalty/program/${programId}/reward/${reward.id}`, rewardData);
          } else {
            // Crear nueva recompensa
            await axios.post(`/loyalty/program/${programId}/reward`, rewardData);
          }
        }
        
        // Notificar éxito
        this.$emit('saved');
      } catch (error) {
        console.error("Error al guardar el programa:", error);
        this.makeToast("Error", `Error al guardar el programa: ${error.message}`, "danger");
      }
    },
    
    makeToast(title, content, variant = "default") {
      this.$bvToast.toast(content, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 5000
      });
    }
  }
};
</script>

<style scoped>
/* Estilos adicionales pueden ser agregados aquí */
</style>