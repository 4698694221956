<template>
    <div>
        <b-modal id="modal-loading" centered no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
            title="Procesando">
            <div class="text-center my-3">
                <b-spinner variant="primary" style="width: 3rem; height: 3rem;" label="Cargando..."></b-spinner>
                <h4 class="mt-3">{{ mensaje }}</h4>
                <p v-if="descripcion">{{ descripcion }}</p>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'LoadingModal',
    props: {
        mensaje: {
            type: String,
            default: 'Cargando...'
        },
        descripcion: {
            type: String,
            default: 'Por favor espere mientras procesamos su solicitud.'
        }
    },
    methods: {
        mostrar() {
            console.log('mostrar');
            this.$bvModal.show('modal-loading');
        },
        ocultar() {
            console.log('ocultar');

            this.$bvModal.hide('modal-loading');
        }
    }
}
</script>