<template>
    <div>
        <Nav></Nav>
        <b-container fluid>
            <b-row>
                <b-col class="text-left">
                    <h1>Conteo Físico #{{ actualGroup.id }}</h1>
                </b-col>
            </b-row>
            <b-row class="text-left">
                <b-col md="12" lg="6">
                    <b-button v-show="actualGroup.state == 'draft'" :disabled="lockButtons || items.length > 0"
                        @click="$bvModal.show('modal-loadList')" size="sm mr-3" variant="default"
                        class="ml-2 mb-2">Cargar
                        Lista de Inventario</b-button>
                    <b-button :disabled="lockButtons" v-show="actualGroup.state == 'draft'" @click="completeGroup"
                        size="sm mr-3" variant="success" class="ml-2 mb-2">
                        Completar
                    </b-button>
                    <b-button :disabled="lockButtons" v-show="actualGroup.state == 'completed'" @click="validateGroup"
                        size="sm mr-3" variant="success" class="ml-2 mb-2">
                        Validar
                    </b-button>
                    <b-button :disabled="lockButtons"
                        v-show="actualGroup.state == 'completed' || actualGroup.state == 'canceled'"
                        @click="changeState('reopen')" size="sm mr-3" variant="warning" class="ml-2 mb-2">
                        Reabrir
                    </b-button>
                    <b-button :disabled="lockButtons" v-show="actualGroup.state == 'draft'"
                        @click="makeAction('recalculate')" size="sm mr-3" variant="warning" class="ml-2 mb-2">
                        Recalcular
                    </b-button>
                    <b-button :disabled="lockButtons"
                        v-show="actualGroup.state == 'draft' || actualGroup.state == 'completed'"
                        @click="changeState('cancel')" size="sm mr-3" variant="danger" class="ml-2 mb-2">
                        Cancelar
                    </b-button>
                </b-col>
                <b-col md="12" lg="6">
                    <progress-steps :steps="processSteps" :currentStep="actualGroup.state"></progress-steps>
                </b-col>
            </b-row>
            <b-card class="my-2 p-1">
                <b-row>
                    <b-col lg="6" md="12">
                        <table class="table table-borderless text-left table-sm">
                            <tbody>
                                <tr>
                                    <td><b>Nombre:</b></td>
                                    <td>{{ actualGroup.name }}</td>
                                </tr>
                                <tr>
                                    <td><b>Ubicación:</b></td>
                                    <td>{{ actualGroup.location ? actualGroup.location.complete_name : '' }}</td>
                                </tr>
                                <tr>
                                    <td><b>Status:</b></td>
                                    <td><b-badge :variant="statusBadge(actualGroup.state).variant">{{
                                        statusBadge(actualGroup.state).text }}</b-badge></td>
                                </tr>
                                <tr>
                                    <td><b>Contado Por:</b></td>
                                    <td>{{ actualGroup.counted_by_user ? actualGroup.counted_by_user.name : '' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                    <b-col lg="6" md="12">
                        <table class="table table-borderless text-left table-sm">
                            <tbody>
                                <tr>
                                    <td><b>Fecha:</b></td>
                                    <td>{{ actualGroup.count_date }}</td>
                                </tr>
                                <tr>
                                    <td><b>Validado Por:</b></td>
                                    <td>{{ actualGroup.validated_by_user ? actualGroup.validated_by_user.name : '' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td><b>Notas:</b></td>
                                    <td>{{ actualGroup.notes }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <table class="table table-sm text-left table-responsive">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Producto</th>
                                    <th>Unidad</th>
                                    <th>Cantidad Sistema</th>
                                    <th>Cantidad Contada</th>
                                    <th>Diferencia</th>
                                    <th>Notas</th>
                                    <th>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in items" v-bind:key="item.id">
                                    <td>{{ item.id }}</td>
                                    <td>{{ item.supply ? item.supply.name : '' }}</td>
                                    <td>{{ item.supply ? item.supply.unit : '' }}</td>
                                    <td>{{ item.system_quantity }}</td>
                                    <td>{{ item.counted_quantity }}</td>
                                    <td>{{ item.difference }}</td>
                                    <td>{{ item.notes }}</td>
                                    <td>
                                        <b-icon-pencil-fill @click="getItem(item.id)" variant="success"
                                            class="mx-2"></b-icon-pencil-fill>
                                        <b-icon-trash-fill @click="deleteItem(item.id)" variant="danger"
                                            class="mx-2"></b-icon-trash-fill>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-row class="text-left my-2">
                    <b-col>
                        <b-button @click="emptyNewItem(); $bvModal.show('modal-newProduct')" size="sm mr-3">Agregar
                            Producto</b-button>
                    </b-col>
                </b-row>
            </b-card>
        </b-container>

        <!-- Modal for new/edit item -->
        <b-modal id="modal-newProduct" @close="emptyNewItem" no-close-on-backdrop :hide-footer="true" size="xl"
            title="Agregar Producto">
            <b-row>
                <b-col lg="12" cols="12">
                    <b-form-group label="Producto">
                        <vu-select v-model.number="newItem.supply_id" :options="supplies" :reduce="(x) => x.id"
                            label="name">
                        </vu-select>
                    </b-form-group>
                </b-col>

                <b-col lg="6" cols="12">
                    <b-form-group label="Cantidad Sistema">
                        <b-form-input v-model.number="newItem.system_quantity" type="number" readonly></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" cols="12">
                    <b-form-group label="Cantidad Contada">
                        <b-input-group>
                            <b-form-input ref="countedQty" v-model.number="newItem.counted_quantity"
                                type="number"></b-form-input>
                            <VueInputCalculator :is-history-logs="false" :enable-keyboard="true"
                                v-model="newItem.counted_quantity">
                                <b-input-group-append>
                                    <b-button variant="info">
                                        <b-icon icon="calculator"></b-icon>
                                    </b-button>
                                </b-input-group-append>
                            </VueInputCalculator>

                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col lg="12" cols="12">
                    <b-form-group label="Notas">
                        <b-form-input v-model="newItem.notes"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="my-3">
                    <b-button class="mr-3" @click="createItem" variant="success">Guardar</b-button>
                    <b-button class="mr-3" @click="$bvModal.hide('modal-newProduct')"
                        variant="danger">Cancelar</b-button>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="modal-loadList" :hide-footer="true" size="xl" title="Cargar lista de compras">
            <b-row>
                <b-col lg="12" cols="12">
                    <b-form-group label="Lista de Compras"
                        description="Selecciona la lista de compras que quieres cargar">
                        <vu-select v-model.number="selectedShoppingList" :options="shoppingLists" :reduce="(x) => x.id"
                            label="name"></vu-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <!-- <b-row>
                <b-col>
                    <ul>
                        <li v-for="item in shoppingListItemList" v-bind:key="item.id">{{ item.name }}</li>
                    </ul>
                </b-col>
            </b-row> -->
            <b-row>
                <b-col>
                    <b-button @click="addShoppinglistitemsToCount" variant="success" class="mr-1 mb-1">Agregar a mi
                        orden</b-button>
                    <b-button variant="danger" class="mr-1 mb-1"
                        @click="$bvModal.hide('modal-loadList')">Cancelar</b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ProgressSteps from "../components/ProgressSteps.vue";
import VueInputCalculator from "vue-input-calculator";

export default {
    name: "AjusteInventario",
    components: {
        Nav,
        ProgressSteps,
        VueInputCalculator,
    },
    data() {
        return {
            selectedShoppingList: null,
            shoppingLists: [],
            addingShoppingList: false,
            lockButtons: false,
            processSteps: [
                { name: "canceled", label: "Cancelado" },
                { name: "draft", label: "Borrador" },
                { name: "completed", label: "Completado" },
                { name: "assigned", label: "Validado" }
            ],
            supplies: [],
            items: [],
            selectedSupply: {
                unit: ""
            },
            actualGroup: {
                id: null,
                name: "",
                state: "draft",
                count_date: null,
                location_id: null,
                location: null,
                counted_by: null,
                counted_by_user: null,
                validated_by: null,
                validated_by_user: null,
                notes: ""
            },
            newItem: {
                id: null,
                supply_id: null,
                location_id: null,
                count_group_id: null,
                counted_quantity: 0,
                system_quantity: 0,
                difference: 0,
                notes: ""
            }
        };
    },
    mounted() {
        if (this.$route.params.id > 0) {
            this.actualGroup.id = this.$route.params.id;
            this.getGroup();
            this.getItems();
            this.getSupplies();
            this.getShoppingLists();
        }
    },
    methods: {
        addShoppinglistitemsToCount() {
            if (this.addingShoppingList) {
                this.makeToast("danger", "Error", "La lista esta siendo cargada, espera un momento")
                return
            }
            this.addingShoppingList = true
            let that = this
            axios.post(`/stock/physicalcount/group/${this.actualGroup.id}/addShoppingList/${this.selectedShoppingList}`)
                .then(function (res) {
                    console.log(res)
                    that.$bvModal.hide("modal-loadList")
                    that.getItems()
                    that.addingShoppingList = false
                }).catch(function (err) {
                    that.makeToast("danger", "Error", err.response.data)
                })
        },
        getShoppingLists() {
            let that = this
            axios.get("/shoppinglists")
                .then(function (res) {
                    that.shoppingLists = res.data
                }).catch(function (err) {
                    that.makeToast("danger", "Error", err.response.data)
                })
        },
        statusBadge(state) {
            const badges = {
                canceled: { variant: "warning", text: "Cancelado" },
                draft: { variant: "warning", text: "Borrador" },
                completed: { variant: "primary", text: "Completado" },
                assigned: { variant: "success", text: "Validado" }
            };
            return badges[state] || { variant: "", text: state };
        },
        getItem(id) {
            axios.get(`/stock/physicalcount/group/${this.actualGroup.id}/item/` + id)
                .then(res => {
                    console.log(res.data);
                    this.newItem = res.data;
                    this.$bvModal.show('modal-newProduct')
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));


        },
        getGroup() {
            axios.get(`/stock/physicalcount/group/${this.actualGroup.id}`)
                .then(res => {
                    this.actualGroup = res.data;
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));
        },

        getItems() {
            axios.get(`/stock/physicalcount/group/${this.actualGroup.id}/items`)
                .then(res => {
                    this.items = res.data.sort((e) => e.supply_category_id);
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));
        },

        getSupplies() {
            let that = this
            axios.get("/supplies")
                .then(function (res) {
                    console.log(res.data.length)
                    let sup = res.data.filter(el => el.status)
                    console.log(sup.length)
                    that.supplies = sup.map(function (el) {
                        return {
                            ...el,
                            name: `${el.code} - ${el.name} (${el.unit})`
                        }
                    })
                    console.log(that.supplies)
                })
                .catch(function (err) {
                    console.log(err)
                    this.makeToast("danger", "Error", err.response)
                });
        },

        createItem() {
            const isNew = !this.newItem.id;
            const method = isNew ? 'post' : 'put';
            const url = isNew
                ? `/stock/physicalcount/group/${this.actualGroup.id}/item`
                : `/stock/physicalcount/group/${this.actualGroup.id}/item/${this.newItem.id}`;

            axios[method](url, this.newItem)
                .then(() => {
                    this.getItems();
                    this.$bvModal.hide('modal-newProduct');
                    this.emptyNewItem();
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));
        },
        changeState(state) {
            let that = this
            this.$bvModal.msgBoxConfirm("Estas seguro que quieres cambiar el status a " + state)
                .then(function () {
                    that.lockButtons = true
                    axios.post(`/stock/physicalcount/group/${that.actualGroup.id}/${state}`)
                        .then(() => {
                            that.getGroup();
                            that.makeToast("success", "Éxito", "Status cambiado " + state);
                            that.lockButtons = false
                        })
                        .catch(err => that.makeToast("danger", "Error", err.response.data));
                })

        },
        makeAction(state) {
            let that = this
            this.lockButtons = true
            axios.post(`/stock/physicalcount/group/${that.actualGroup.id}/${state}`)
                .then((res) => {
                    that.lockButtons = false
                    that.getGroup();
                    that.getItems()
                    that.makeToast("success", "Éxito", res.data);
                })
                .catch(err => that.makeToast("danger", "Error", err.response.data));
        },
        completeGroup() {
            let that = this
            this.lockButtons = true
            axios.post(`/stock/physicalcount/group/${this.actualGroup.id}/complete`)
                .then(() => {
                    that.lockButtons = false

                    that.getGroup();
                    that.makeToast("success", "Éxito", "Conteo completado");
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));
        },

        validateGroup() {
            let that = this
            this.lockButtons = true
            axios.post(`/stock/physicalcount/group/${this.actualGroup.id}/validate`)
                .then(() => {
                    that.lockButtons = false
                    that.getGroup();
                    that.makeToast("success", "Éxito", "Conteo validado");
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));
        },

        deleteItem(id) {
            axios.delete(`/stock/physicalcount/group/${this.actualGroup.id}/item/${id}`)
                .then(() => {
                    this.getItems();
                    this.makeToast("success", "Éxito", "Item eliminado");
                })
                .catch(err => this.makeToast("danger", "Error", err.response.data));
        },

        emptyNewItem() {
            this.newItem = {
                id: null,
                supply_id: null,
                location_id: this.actualGroup.location_id,
                count_group_id: this.actualGroup.id,
                counted_quantity: 0,
                system_quantity: 0,
                difference: 0,
                notes: ""
            };
        },

        makeToast(variant, title, text) {
            this.$bvToast.toast(text, {
                title,
                variant,
                solid: true,
                appendToast: true
            });
        }
    }
};
</script>