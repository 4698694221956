<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div>
        <h3>{{ program.name }}</h3>
        <div class="d-flex align-items-center">
          <span :class="['status-dot', program.active ? 'active' : 'inactive']"></span>
          <span class="text-muted ml-2">{{ program.active ? 'Activo' : 'Inactivo' }}</span>
        </div>
      </div>
      
      <div>
        <b-button variant="outline-secondary" class="mr-2" @click="showPreview = !showPreview">
          <i class="fas fa-eye mr-1"></i> {{ showPreview ? 'Ocultar Visualizador' : 'Visualizar Promoción' }}
        </b-button>
        <b-button variant="outline-primary" @click="$emit('edit')">
          <i class="fas fa-edit mr-1"></i> Editar
        </b-button>
      </div>
    </div>
    
    <!-- Visualizador de Promoción -->
    <program-preview v-if="showPreview" :program="program" class="mb-4"></program-preview>
    
    <b-row>
      <b-col md="6">
        <!-- Información General -->
        <b-card class="mb-4">
          <template #header>
            <h5 class="mb-0">Información General</h5>
          </template>
          
          <dl class="row mb-0">
            <dt class="col-sm-4">Tipo de Programa:</dt>
            <dd class="col-sm-8">{{ getProgramTypeLabel(program.program_type) }}</dd>
            
            <dt class="col-sm-4">Fecha de Creación:</dt>
            <dd class="col-sm-8">{{ formatDate(program.create_date) }}</dd>
            
            <dt class="col-sm-4">Producto para Descuento:</dt>
            <dd class="col-sm-8">ID: {{ program.discount_line_product_id }}</dd>
            
            <dt class="col-sm-4">Límite de Uso:</dt>
            <dd class="col-sm-8">
              <i :class="['fas', program.limit_usage ? 'fa-check text-success' : 'fa-times text-danger']"></i>
              {{ program.limit_usage ? 'Sí' : 'No' }}
            </dd>
            
            <dt class="col-sm-4">Visible en Portal:</dt>
            <dd class="col-sm-8">
              <i :class="['fas', program.portal_visible ? 'fa-check text-success' : 'fa-times text-danger']"></i>
              {{ program.portal_visible ? 'Sí' : 'No' }}
            </dd>
            
            <template v-if="program.require_extra">
              <dt class="col-sm-4">Información Adicional:</dt>
              <dd class="col-sm-8">{{ program.extra_description }}</dd>
            </template>
          </dl>
        </b-card>
        
        <!-- Reglas -->
        <b-card class="mb-4">
          <template #header>
            <h5 class="mb-0">Reglas</h5>
          </template>
          
          <div v-if="program.rules && program.rules.length > 0">
            <div v-for="(rule, index) in program.rules" :key="index" 
              class="p-3 mb-2 bg-light rounded">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <strong>{{ rule.mode === 'with_code' ? `Código: ${rule.code}` : 'Regla Automática' }}</strong>
                  <div class="text-muted">
                    Modo: {{ getRulePointModeLabel(rule.reward_pointmode) }}
                  </div>
                  
                  <div v-if="rule.minimum_amount > 0" class="text-muted">
                    Monto mínimo: ${{ rule.minimum_amount }}
                  </div>
                  
                  <div v-if="rule.minimum_qty" class="text-muted">
                    Cantidad mínima: {{ rule.minimum_qty }}
                  </div>
                  
                  <div class="text-muted">
                    Puntos otorgados: {{ rule.reward_point_amount }}
                  </div>
                </div>
                
                <div>
                  <span :class="['badge', rule.active ? 'badge-success' : 'badge-danger']">
                    {{ rule.active ? 'Activo' : 'Inactivo' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center text-muted py-3">
            No hay reglas configuradas
          </div>
        </b-card>
      </b-col>
      
      <b-col md="6">
        <!-- Recompensas -->
        <b-card class="mb-4">
          <template #header>
            <h5 class="mb-0">Recompensas</h5>
          </template>
          
          <div v-if="program.rewards && program.rewards.length > 0">
            <div v-for="(reward, index) in program.rewards" :key="index" 
              class="p-3 mb-2 bg-light rounded">
              <div class="d-flex justify-content-between align-items-start">
                <div>
                  <strong>{{ reward.description }}</strong>
                  
                  <template v-if="reward.reward_type === 'discount'">
                    <div class="text-muted">
                      Tipo: Descuento ({{ getDiscountModeLabel(reward.discount_mode) }})
                    </div>
                    <div class="text-muted">
                      Valor: {{ reward.discount_mode === 'percent' ? `${reward.discount}%` : `$${reward.discount}` }}
                    </div>
                    <div class="text-muted">
                      Aplicable a: {{ reward.discount_applicability === 'order' ? 'Toda la orden' : 'Productos específicos' }}
                    </div>
                    <div v-if="reward.discount_max_amount > 0" class="text-muted">
                      Máximo descuento: ${{ reward.discount_max_amount }}
                    </div>
                  </template>
                  
                  <template v-else>
                    <div class="text-muted">
                      Tipo: Producto Gratis
                    </div>
                    <div class="text-muted">
                      Producto ID: {{ reward.reward_product_id }}
                    </div>
                    <div class="text-muted">
                      Cantidad: {{ reward.reward_product_qty }}
                    </div>
                  </template>
                  
                  <div class="text-muted mt-1">
                    Puntos requeridos: {{ reward.required_points }}
                  </div>
                </div>
                
                <div>
                  <span :class="['badge', reward.active ? 'badge-success' : 'badge-danger']">
                    {{ reward.active ? 'Activo' : 'Inactivo' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-center text-muted py-3">
            No hay recompensas configuradas
          </div>
        </b-card>
        
        <!-- Uso del Programa -->
        <b-card>
          <template #header>
            <h5 class="mb-0">Cómo Usar Este Programa</h5>
          </template>
          
          <div class="mb-4">
            <h6>Códigos de Promoción</h6>
            <div v-if="hasCodes">
              <ul class="list-unstyled">
                <li v-for="(rule, index) in activeCodes" :key="index" class="mb-1">
                  <code class="bg-light p-1 rounded">{{ rule.code }}</code>
                </li>
              </ul>
              <p class="small text-muted">
                Ingresa uno de estos códigos en el cajero para aplicar la promoción.
              </p>
            </div>
            <div v-else class="text-muted small">
              Este programa se aplica automáticamente al cumplir con los requisitos.
            </div>
          </div>
          
          <div>
            <h6>Requisitos</h6>
            <ul class="mb-0">
              <li v-for="(rule, index) in program.rules" :key="index" class="small mb-1">
                <template v-if="rule.reward_pointmode === 'order'">
                  Realizar una orden
                </template>
                <template v-else-if="rule.reward_pointmode === 'money'">
                  Gasto mínimo de ${{ rule.minimum_amount }}
                </template>
                <template v-else-if="rule.reward_pointmode === 'unit'">
                  Comprar al menos {{ rule.minimum_qty }} unidades de productos seleccionados
                </template>
                <template v-else>
                  Cumplir con los requisitos específicos
                </template>
              </li>
              <li v-if="program.limit_usage" class="small mb-1">
                No se puede combinar con otras promociones
              </li>
            </ul>
          </div>
        </b-card>
      </b-col>
    </b-row>
    
    <div class="mt-4 pt-3 border-top">
      <b-button variant="outline-secondary" @click="$emit('back')">
        Volver a la Lista
      </b-button>
    </div>
  </div>
</template>

<script>
import ProgramPreview from './LoyaltyProgramPreview.vue';

export default {
  name: "ProgramDetails",
  components: {
    ProgramPreview
  },
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPreview: false
    };
  },
  computed: {
    hasCodes() {
      return this.program.rules && 
             this.program.rules.some(rule => rule.mode === 'with_code' && rule.active);
    },
    activeCodes() {
      if (!this.program.rules) return [];
      return this.program.rules.filter(rule => rule.mode === 'with_code' && rule.active);
    }
  },
  methods: {
    getProgramTypeLabel(type) {
      const types = {
        'discount': 'Descuento',
        'buy_x_get_y': 'Compra X y Lleva Y',
        'free_product': 'Producto Gratis',
        'free_products': 'Productos Gratis',
        'coupons': 'Cupón de Descuento'
      };
      return types[type] || type;
    },
    getRulePointModeLabel(mode) {
      const modes = {
        'order': 'Puntos por orden',
        'money': 'Puntos por monto',
        'unit': 'Puntos por unidad'
      };
      return modes[mode] || mode;
    },
    getDiscountModeLabel(mode) {
      const modes = {
        'percent': 'Porcentaje',
        'per_order': 'Por Orden',
        'per_point': 'Por Punto'
      };
      return modes[mode] || mode;
    },
    formatDate(dateString) {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return date.toLocaleDateString();
    }
  }
};
</script>

<style scoped>
.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.status-dot.active {
  background-color: #28a745;
}
.status-dot.inactive {
  background-color: #dc3545;
}
</style>
