<template>
<div>
    <Nav></Nav>
    <b-container fluid class="mt-3">
    <ReportSearchBar :init="routeQuery.fini" :end="routeQuery.ffin" :sucursales="routeQuery.sucursales" :emitOnChange="false" @search="searchRecv" @changed="changeData"></ReportSearchBar>
    <b-row>
        <b-col>
        <h1>Ajustes de Inventario</h1>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
        <b-button variant="success" @click="$bvModal.show('modal-newGroup')">Crear Ajuste</b-button>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
        <b-form-group label="Buscar:">
            <b-form-input type="search" v-model="routeQuery.search" @keyup="updateQuery" debounce="500"></b-form-input>
        </b-form-group>
        </b-col>
    </b-row>

    <b-row>
        <b-col>
        <b-table ref="selectableTable" selectable @row-selected="onRowSelected" small :filter="routeQuery.search" striped hover :items="groups" :fields="groupLabels" responsive>
            <template #table-busy>
            <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
            </div>
            </template>
            <template #cell(state)="data">
            <b-badge :variant="statusBadge(data.item.state).variant">{{ statusBadge(data.item.state).text }}</b-badge>
            </template>
            <template #cell(buttons)="data">
            <b-button size="sm" variant="success" @click="$router.push('/inventario/ajuste/' + data.item.id)" class="mr-1">
                +Info
            </b-button>
            </template>
        </b-table>
        </b-col>
    </b-row>

    <b-modal id="modal-newGroup" :hide-footer="true" size="xl" title="Crear Ajuste de Inventario">
        <CreateStockAdjustment @created="newGroupCreated" @error="newGroupError"></CreateStockAdjustment>
    </b-modal>
    </b-container>
</div>
</template>

<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ReportSearchBar from "./reports/searchBar/ReportSearchBar.vue";
import moment from 'moment';
import CreateStockAdjustment from "../components/CreateStockAdjustment.vue";

export default {
name: "AjustesInventario",
components: {
    Nav, 
    ReportSearchBar,
    CreateStockAdjustment
},
data() {
    return {
    routeQuery: {
        page:null,
        search:null,
        fini:null,
        ffin:null,
        sucursales:null
    },
    initialDate: "",
    lastDate: "",
    allSucsArray: [],
    groups: [],
    groupLabels: [
        { key: "id", label: "ID" },
        { key: "name", label: "Nombre" },
        { key: "location.complete_name", label: "Ubicación" },
        { key: "counted_by_user.name", label: "Contado Por" },
        { key: "state", label: "Estado", sortable: true },
        { key: "count_date", label: "Fecha", sortable: true },
        { key: "buttons", label: "" }
    ]
    };
},
mounted() {
    this.routeQuery = {
    search: this.$route.query.search,
    ffin: this.$route.query.ffin,
    fini: this.$route.query.fini,
    sucursales: this.$route.query.sucursales
    };
},
methods: {
    onRowSelected(item) {
    if(item.length > 0) {
        this.$router.push('/inventario/ajuste/' + item[0].id);
    }
    this.$refs.selectableTable.clearSelected();
    },

    updateQuery() {
    const updates = { ...this.$route.query };
    Object.entries(this.routeQuery).forEach(([key, value]) => {
        if (!value) {
        delete updates[key];
        } else {
        updates[key] = value;
        }
    });
    this.$router.replace({ query: updates });
    },

    changeData(e) {
        console.log("changeData")
        this.routeQuery.ffin = moment(e.end).format('YYYY-MM-DD');
        this.routeQuery.fini = moment(e.init).format('YYYY-MM-DD');
        this.routeQuery.sucursales = e.sucursales.join(",");
        this.updateQuery();
    },

    searchRecv(p) {
        console.log("searchRecv")
        console.log(p)
        this.initialDate = moment(p.initDate).format('YYYY-MM-DD');
        this.lastDate = moment(p.endDate).format('YYYY-MM-DD');
        this.allSucsArray = p.sucursales;
        this.getGroups();
    },

    statusBadge(state) {
    const badges = {
        canceled: { variant: "danger", text: "Cancelado" },
        draft: { variant: "warning", text: "Borrador" },
        completed: { variant: "info", text: "Completado" },
        assigned: { variant: "success", text: "Validado" }
    };
    return badges[state] || { variant: "", text: state };
    },
    getGroups() {
    axios.get(`/stock/physicalcount/groups?sucursales=${this.allSucsArray.join(",")}&init_date=${this.initialDate}&end_date=${this.lastDate}`)
        .then(res => {
        this.groups = res.data;
        })
        .catch(err => this.makeToast("danger", "Error", err.response.data));
    },

    newGroupCreated(id) {
    this.$router.push('/inventario/ajuste/' + id);
    this.getGroups();
    },

    newGroupError(err) {
    this.makeToast("danger", "Error", err.data);
    this.getGroups();
    },

    makeToast(variant, title, text) {
    this.$bvModal.msgBoxOk(text, {
        title,
        okVariant: variant,
        centered: true
    });
    }
}
};
</script>