import { render, staticRenderFns } from "./LoyaltyProgramPreview.vue?vue&type=template&id=3364e3ca&scoped=true"
import script from "./LoyaltyProgramPreview.vue?vue&type=script&lang=js"
export * from "./LoyaltyProgramPreview.vue?vue&type=script&lang=js"
import style0 from "./LoyaltyProgramPreview.vue?vue&type=style&index=0&id=3364e3ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3364e3ca",
  null
  
)

export default component.exports